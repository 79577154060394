import { AttachmentColumnModel, BooleanColumnModel, CurrencyColumnModel, DateColumnModel, DateTimeColumnModel, DurationColumnModel, FormattedReferenceColumnModel, LinkColumnModel, MapOptionColumnModel, NumberColumnModel, OptionColumnModel, OptionsColumnModel, SemaphoreColumnModel, StringColumnModel, TimeColumnModel } from "components/Table";
import { ColumnsOrderType, ColumnsWidthType } from "features/UserTableViewConfig/tableConfig/reducer";
import { GenericColumnModel } from "components/Table";
import { useMemo } from "react";

export const cloneColumnModel = (column: GenericColumnModel): GenericColumnModel => {
	if (column instanceof FormattedReferenceColumnModel) {
		return new FormattedReferenceColumnModel(column);
	} else if (column instanceof SemaphoreColumnModel) {
		return new SemaphoreColumnModel(column);
	} else if (column instanceof DateColumnModel) {
		return new DateColumnModel(column);
	} else if (column instanceof DateTimeColumnModel) {
		return new DateTimeColumnModel(column);
	} else if (column instanceof CurrencyColumnModel) {
		return new CurrencyColumnModel(column);
	} else if (column instanceof OptionsColumnModel) {
		return new OptionsColumnModel(column);
	} else if (column instanceof OptionColumnModel) {
		return new OptionColumnModel(column);
	} else if (column instanceof MapOptionColumnModel) {
		return new MapOptionColumnModel(column);
	} else if (column instanceof LinkColumnModel) {
		return new LinkColumnModel(column);
	} else if (column instanceof AttachmentColumnModel) {
		return new AttachmentColumnModel(column);
	} else if (column instanceof TimeColumnModel) {
		return new TimeColumnModel(column);
	} else if (column instanceof DurationColumnModel) {
		return new DurationColumnModel(column);
	} else if (column instanceof BooleanColumnModel) {
		return new BooleanColumnModel(column);
	} else if (column instanceof NumberColumnModel) {
		return new NumberColumnModel(column);
	} else if (column instanceof StringColumnModel) {
		return new StringColumnModel(column);
	}

	// it should never come here
	return new GenericColumnModel(column);
}

const applyDefaults = (columns: GenericColumnModel[]) => {
	for (let column of columns) {
		if (column instanceof CurrencyColumnModel) {
			column.align = column.align || 'right';
		}
	}
}

const applyColumnsWidth = (columns: GenericColumnModel[], columnsWidth: ColumnsWidthType) => {
	for (let column of columns) {
		column.width = columnsWidth[column.id] || column.width;
	}
};

const toVisibleHiddenColumns = (tableColumns: GenericColumnModel[], visibleColumnsOrder: string[]) => {
	const visibleColumns: GenericColumnModel[] = [];
	for (let columnId of visibleColumnsOrder) {
		const column = tableColumns.find((item) => item.id === columnId);
		if (column) {
			visibleColumns.push(column);
		}
	}

	const hiddenColumns: GenericColumnModel[] = [];
	for (let tableColumn of tableColumns) {
		if (!visibleColumnsOrder.includes(tableColumn.id)) {
			hiddenColumns.push(tableColumn);
		}
	}

	return { visibleColumns, hiddenColumns };
}

export const usePrepareTableColumns = (
	tableColumns: GenericColumnModel[],
	defaultVisibleColumns: string[],
	columnsOrder: ColumnsOrderType | undefined,
	columnsWidth: ColumnsWidthType | undefined
) => {
	return useMemo(
		() => {
			// TODO:code_improvement we clone all columns which may lead to some performance issues,
			// so check how are renders behaving on table initialization and interactions
			const clonedColumns: GenericColumnModel[] = [];
			for (let tableColumn of tableColumns) {
				clonedColumns.push(cloneColumnModel(tableColumn));
			}
			const visibleColumnsOrder = columnsOrder || defaultVisibleColumns;
			const { visibleColumns, hiddenColumns } = toVisibleHiddenColumns(clonedColumns, visibleColumnsOrder);
			applyColumnsWidth(visibleColumns, columnsWidth || {});
			applyDefaults(visibleColumns);

			return { visibleColumns, hiddenColumns };
		},
		[tableColumns, defaultVisibleColumns, columnsOrder, columnsWidth]
	)
}
