import { productionConfig } from "./productionConfig";
import { stagingConfig } from "./stagingConfig";

export type Config = {
	Sentry?: {
		dsn: string
	}
}

let config: Config = stagingConfig;

if (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE !== 'development') {
	config = productionConfig;
}

export { config }
