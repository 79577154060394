import { createContext, useContext, useReducer } from 'react';
import { LanguagesEnum } from 'services/tenantManagementService';

export type LanguageType = {
	shortName: string
	fullName: string
	locale: string
	iconUri: string
	value: LanguagesEnum
}

export const languages: { [key in LanguagesEnum]: LanguageType } = {
	[LanguagesEnum.English]: {
		shortName: 'ENG',
		fullName: 'English',
		locale: 'en-US',
		iconUri: '/assets/flags/united_kingdom_32x32.png',
		value: LanguagesEnum.English
	},
	[LanguagesEnum.Serbian]: {
		shortName: 'SRP',
		fullName: 'Srpski',
		locale: 'sr',
		iconUri: '/assets/flags/serbia_32x32.png',
		value: LanguagesEnum.Serbian
	}
}

export const languageItems: LanguageType[] = Object.keys(languages).map((langKey) => {
	return {
		...languages[langKey]
	}
})

export enum TranslationKeyEnum {
	BASE = 'base',
	PROJECTS = 'projects',
	TICKETS = 'tickets',
	SCOPE = 'scope',
	TESTING = 'testing',
	TRAINING = 'training',
	TIME_TRAVEL = 'time_travel',
	RISK = 'risk',
	FINANCE = 'finance',
	COMMUNICATION = 'communication',
	SCHEDULE = 'schedule',
	SITE_CONFIGURATION = 'site_configuration',
	ACCOUNT = 'account'
}

type TranslationState = {
	translations: { [key: string]: string }
	loadedTranslations: { [key: string]: boolean }
	loadingInProgress: Set<string>
}

export type TranslationAction = {
	type: 'LOAD_TRANSLATION' | 'SET_TRANSLATION_LOADED' | 'SET_SELECTED_LANGUAGE'
	payload: any
}

const initialState: TranslationState = {
	translations: {},
	loadedTranslations: {
		[TranslationKeyEnum.BASE]: false,
		[TranslationKeyEnum.PROJECTS]: false,
		[TranslationKeyEnum.TICKETS]: false,
		[TranslationKeyEnum.SCOPE]: false,
		[TranslationKeyEnum.TESTING]: false,
		[TranslationKeyEnum.TRAINING]: false,
		[TranslationKeyEnum.TIME_TRAVEL]: false,
		[TranslationKeyEnum.RISK]: false,
		[TranslationKeyEnum.FINANCE]: false,
		[TranslationKeyEnum.COMMUNICATION]: false,
		[TranslationKeyEnum.SCHEDULE]: false,
		[TranslationKeyEnum.SITE_CONFIGURATION]: false,
		[TranslationKeyEnum.ACCOUNT]: false
	},
	loadingInProgress: new Set()
}

const translationReducer = (state: TranslationState, action: TranslationAction): TranslationState => {
	switch (action.type) {
		case 'LOAD_TRANSLATION':
			return {
				...state,
				loadingInProgress: new Set(state.loadingInProgress).add(action.payload)
			};
		case 'SET_TRANSLATION_LOADED':
			return {
				...state,
				translations: {
					...state.translations,
					...action.payload.translations
				},
				loadedTranslations: {
					...state.loadedTranslations,
					[action.payload.routeId]: true
				},
				loadingInProgress: new Set(Array.from(state.loadingInProgress).filter(route => route !== action.payload.routeId))
			};
		case 'SET_SELECTED_LANGUAGE':
			return {
				...state,
				loadedTranslations: {
					[TranslationKeyEnum.BASE]: false,
					[TranslationKeyEnum.PROJECTS]: false,
					[TranslationKeyEnum.TICKETS]: false,
					[TranslationKeyEnum.SCOPE]: false,
					[TranslationKeyEnum.TESTING]: false,
					[TranslationKeyEnum.TRAINING]: false,
					[TranslationKeyEnum.TIME_TRAVEL]: false,
					[TranslationKeyEnum.RISK]: false,
					[TranslationKeyEnum.FINANCE]: false,
					[TranslationKeyEnum.COMMUNICATION]: false,
					[TranslationKeyEnum.SCHEDULE]: false,
					[TranslationKeyEnum.SITE_CONFIGURATION]: false,
					[TranslationKeyEnum.ACCOUNT]: false
				},
				loadingInProgress: new Set()
			};
		default:
			return state;
	}
}

const TranslationsContext = createContext<TranslationState>(initialState);
const TranslationsDispatchContext = createContext<any>(null);

/** It is bad to use it. It makes sence to use it in prepareExportColumns
 * only because it is used in a callback to send titles to backend.
 * It is not meant to be used in React components nor for display on UI as it will not notify them when something is changed. */
export let exportedTranslationsState: TranslationState;

export const TranslationsProvider = ({ children }) => {
	const [state, dispatch] = useReducer(translationReducer, initialState);

	exportedTranslationsState = state;

	return (
		<TranslationsContext.Provider value={state}>
			<TranslationsDispatchContext.Provider value={dispatch}>
				{children}
			</TranslationsDispatchContext.Provider>
		</TranslationsContext.Provider>
	);
}

export const useTranslations = () => {
	return useContext(TranslationsContext);
}

export const useTranslationsDispatch = () => {
	return useContext(TranslationsDispatchContext);
}
