import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ModuleActivityEnum, NonProjectCategoryResponse, ProjectResponse } from 'services/tenantManagementService';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { RootState } from 'base/reducer/reducer';
import { ColumnContainer } from 'components/Layout';
import { Subtract } from 'utility-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useProjectsAndCategories } from '../projectHooks';
import { ProjectOrCategoryMultiSelect } from '../controls/ProjectOrCategoryMultiSelect';
import { ProjectOrCategoryType } from '../projectHooks';

export type ProjectsOrCategoriesComponentProps = {
	projects: ProjectResponse[]
	nonProjectCategories: NonProjectCategoryResponse[]
}

export function WithProjectsOrCategoriesPicker<P extends ProjectsOrCategoriesComponentProps>(
	Component: React.ComponentType<P>,
	moduleEnum: ModuleActivityEnum,
	useMargin?: boolean
) {

	const HighOrderComponent = (props: Subtract<P, ProjectsOrCategoriesComponentProps>) => {
		const history = useHistory();
		const { search } = useLocation();
		const searchParams = new URLSearchParams(search);
		const urlProjectIds = searchParams.get('projectIds')?.split(',').map(Number) || [];
		const urlCategoryIds = searchParams.get('categoryIds')?.split(',').map(Number) || [];
		const persistedProject = useSelector((state: RootState) => state.persistedProject);
		const persistedTimeTravelNonProjectCategories = useSelector((state: RootState) => state.persistedTimeTravelNonProjectCategories);

		const { projectsOrCategories } = useProjectsAndCategories({
			showCompleted: true,
			moduleEnum
		});

		// initial state should be the one from URL, and later on, state from URL should be ignored
		let initialProjectsOrCategories: ProjectOrCategoryType[] = [];
		for (let projectId of urlProjectIds) {
			initialProjectsOrCategories.push({
				projectOrCategoryId: projectId,
				isProjectConnected: true
			});
		}

		for (let categoryId of urlCategoryIds) {
			initialProjectsOrCategories.push({
				projectOrCategoryId: categoryId,
				isProjectConnected: false
			});
		}

		const [selectedProjectsOrCategories, setSelectedProjectsOrCategories] = useState<ProjectOrCategoryType[] | undefined>(initialProjectsOrCategories);
		const [firstLoading, setFirstLoading] = useState(true);

		useEffect(
			() => {
				// if there is no initial state from URL, or selected projectsOrCategories, or is empty array,
				// set default projects and categories from hook as initial state (if it exists)
				if (firstLoading && (!selectedProjectsOrCategories || selectedProjectsOrCategories.length === 0) && projectsOrCategories.length) {
					setSelectedProjectsOrCategories(projectsOrCategories);
					setFirstLoading(false);
				} else if (firstLoading && (selectedProjectsOrCategories && selectedProjectsOrCategories.length > 0)) {
					// in case when we send a link with selected projects or categories,
					// the page is loaded and firstLoading is true, so we set it to false
					setFirstLoading(false);
				}
			},
			[selectedProjectsOrCategories, projectsOrCategories, firstLoading]
		)

		const selectedProjectsOrCategoriesMemo = useMemo(
			() => {
				let allSelectedProjects: ProjectResponse[] = [];
				let allSelectedCategories: NonProjectCategoryResponse[] = [];

				if (selectedProjectsOrCategories) {
					const selectedProjectIds = selectedProjectsOrCategories.filter(item => item.isProjectConnected === true).map(item => item.projectOrCategoryId);
					for (let id of selectedProjectIds) {
						const project = persistedProject.itemsMap[id];

						if (!project) {
							continue;
						}

						allSelectedProjects.push(project);
					}

					const selectedCategoryIds = selectedProjectsOrCategories.filter(item => item.isProjectConnected === false).map(item => item.projectOrCategoryId);
					for (let id of selectedCategoryIds) {
						const category = persistedTimeTravelNonProjectCategories.itemsMap[id];

						if (!category) {
							continue;
						}

						allSelectedCategories.push(category);
					}
				}

				return { allSelectedProjects, allSelectedCategories};

			},
			[selectedProjectsOrCategories, persistedProject.itemsMap, persistedTimeTravelNonProjectCategories.itemsMap]
		)

		const searchQuery = useMemo(
			() => {
				const selectedProjectIds = selectedProjectsOrCategories?.filter(item => item.isProjectConnected === true).map(item => item.projectOrCategoryId) || [];
				const selectedCategoryIds = selectedProjectsOrCategories?.filter(item => item.isProjectConnected === false).map(item => item.projectOrCategoryId) || [];

				if (selectedProjectIds.length && selectedCategoryIds.length) {
					return '?projectIds=' + selectedProjectIds + '&categoryIds=' + selectedCategoryIds;
				} else if (selectedProjectIds.length) {
					return '?projectIds=' + selectedProjectIds;
				} else if (selectedCategoryIds.length) {
					return '?categoryIds=' + selectedCategoryIds;
				} else {
					return '';
				}
			},
			[selectedProjectsOrCategories]
		)

		useEffect(
			() => {
				history.replace({
					search: searchQuery
				});
			},
			[history, searchQuery, search]
		)

		const WrapperComponent = useMemo(
			() => useMargin ? ColumnContainer : React.Fragment,
			[]
		)

		const componentPropsMemo = useMemo(
			() => {
				return {
					...props,
					projects: selectedProjectsOrCategoriesMemo?.allSelectedProjects,
					nonProjectCategories: selectedProjectsOrCategoriesMemo?.allSelectedCategories
				} as P
			},
			[selectedProjectsOrCategoriesMemo, props]
		)

		return (
			<WrapperComponent>
				<SmartContainer>
					<SmartItem>
						<ProjectOrCategoryMultiSelect
							value={selectedProjectsOrCategories}
							onChange={setSelectedProjectsOrCategories}
							moduleEnum={moduleEnum}
							showCompleted
						/>
					</SmartItem>
				</SmartContainer>
				{(selectedProjectsOrCategories && selectedProjectsOrCategories?.length > 0) && <Component {...componentPropsMemo} />}
			</WrapperComponent>
		)
	}

	return HighOrderComponent;
}
