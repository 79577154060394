import { Translation } from 'components/Translations/Translation';
import { Popup } from '../Popup';
import { POSITION, PLACEMENT } from '../constants';
import { InfoSquaredIcon } from 'components/icons/icons';

type Props = {
	message?: string
	position?: PLACEMENT[]
}

export const InfoPopup = ({ message, position = POSITION.RIGHT_CENTER }: Props) => {
	return (
		<>
			{message &&
				<Popup
					content={
						<p style={{ minWidth: '260px' }}><Translation i18n={message} /></p>
					}
					position={position}
					stayOpen={false}
					initialOpen={false}
				>
					<InfoSquaredIcon width={18} height={18} fill='currentColor' fillOpacity='0.8' />
				</Popup>
			}
			{!message &&
				<InfoSquaredIcon width={18} height={18} fill='currentColor' fillOpacity='0.4' cursor='not-allowed' />
			}
		</>
	)
};
