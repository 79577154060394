import { noop } from 'utils/commonHelper';
import { formatServerDate } from 'utils/dateTimeUtils'
import { sortByString } from 'utils/stringUtil';
import { BooleanColumnModel, CurrencyColumnModel, DateColumnModel, DateTimeColumnModel, DurationColumnModel, FilterType, FormattedReferenceColumnModel, GenericColumnModel, MapOptionColumnModel, NumberColumnModel, OptionColumnModel, OptionGenericColumnModel, OptionsColumnModel, SortType, StringColumnModel, TimeColumnModel } from '../models';
import { GenericFilterOperationEnum } from 'services/tenantManagementService';
import { defaultPaginationLimit } from '../consts';

// filter methods

type PrimitiveType = number | string | boolean

const arePrimitiveTypesEqual = (first: PrimitiveType, second: PrimitiveType) => {
	return first === second;
}

const arePrimitiveTypesNotEqual = (first: PrimitiveType, second: PrimitiveType) => {
	return first !== second;
}

const like = (value: string, filterValue: string) => {
	if (value === filterValue) {
		return true;
	}
	if (value === undefined || filterValue === undefined) {
		return false;
	}

	return value.toLowerCase().includes(filterValue.toLowerCase());
}

const likeStartsWith = (value: string, filterValue: string) => {
	if (value === filterValue) {
		return true;
	}
	if (value === undefined || filterValue === undefined) {
		return false;
	}

	return value.toLowerCase().startsWith(filterValue.toLowerCase());
}

const likeEndsWith = (value: string, filterValue: string) => {
	if (value === filterValue) {
		return true;
	}
	if (value === undefined || filterValue === undefined) {
		return false;
	}

	return value.toLowerCase().endsWith(filterValue.toLowerCase());
}

const greaterEqual = (value: number, filterValue: number) => {
	return value >= filterValue;
}

const greater = (value: number, filterValue: number) => {
	return value > filterValue;
}

const lessEqual = (value: number, filterValue: number) => {
	return value <= filterValue;
}

const less = (value: number, filterValue: number) => {
	return value < filterValue;
}

const areDatesEqual = (first: Date, second: Date) => {
	if (first === second) {
		return true;
	}
	if (first === undefined || second === undefined) {
		return false;
	}

	const firstFormatted = formatServerDate(first)
	const secondFormatted = formatServerDate(second)

	return firstFormatted === secondFormatted;
}

const areDatesNotEqual = (first: Date, second: Date) => {
	return !areDatesEqual(first, second);
}

const dateGreaterEqual = (value: Date, filterValue: Date) => {
	if (value === undefined || filterValue === undefined) {
		return false;
	}

	const fullYear = value.getFullYear();
	const month = value.getMonth();
	const dayOfTheMonth = value.getDate();

	const filterFullYear = filterValue.getFullYear();
	const filterMonth = filterValue.getMonth();
	const filterDayOfTheMonth = filterValue.getDate();

	return (
		fullYear > filterFullYear ||
		(fullYear === filterFullYear && month > filterMonth) ||
		(fullYear === filterFullYear && month === filterMonth && dayOfTheMonth >= filterDayOfTheMonth)
	)
}

const dateGreater = (value: Date, filterValue: Date) => {
	if (value === undefined || filterValue === undefined) {
		return false;
	}

	const fullYear = value.getFullYear();
	const month = value.getMonth();
	const dayOfTheMonth = value.getDate();

	const filterFullYear = filterValue.getFullYear();
	const filterMonth = filterValue.getMonth();
	const filterDayOfTheMonth = filterValue.getDate();

	return (
		fullYear > filterFullYear ||
		(fullYear === filterFullYear && month > filterMonth) ||
		(fullYear === filterFullYear && month === filterMonth && dayOfTheMonth > filterDayOfTheMonth)
	)
}

const dateLessEqual = (value: Date, filterValue: Date) => {
	if (value === undefined || filterValue === undefined) {
		return false;
	}

	return !dateGreater(value, filterValue);
}

const dateLess = (value: Date, filterValue: Date) => {
	if (value === undefined || filterValue === undefined) {
		return false;
	}

	return !dateGreaterEqual(value, filterValue);
}

const doesRowSatisfy = (rowData: any, filteredColumn: GenericColumnModel, filter: FilterType) => {
	let compareFunction: (value: any, filterValue: any) => boolean = noop as any;
	let filterValue = filter.value;

	if (
		filteredColumn instanceof OptionColumnModel ||
		filteredColumn instanceof OptionsColumnModel ||
		filteredColumn instanceof MapOptionColumnModel ||
		filteredColumn instanceof BooleanColumnModel
	) {
		compareFunction = arePrimitiveTypesEqual;
	} else if (filteredColumn instanceof FormattedReferenceColumnModel) {
		switch (filter.operation) {
			case GenericFilterOperationEnum.EQUALS:
				compareFunction = arePrimitiveTypesEqual;
				break;
			case GenericFilterOperationEnum.NOT_EQUALS:
				compareFunction = arePrimitiveTypesNotEqual;
				break;
		}
	} else if (filteredColumn instanceof StringColumnModel) {
		switch (filter.operation) {
			case GenericFilterOperationEnum.EQUALS:
				compareFunction = arePrimitiveTypesEqual;
				break;
			case GenericFilterOperationEnum.NOT_EQUALS:
				compareFunction = arePrimitiveTypesNotEqual;
				break;
			case GenericFilterOperationEnum.LIKE:
				compareFunction = like;
				break;
			case GenericFilterOperationEnum.LIKE_STARTSWITH:
				compareFunction = likeStartsWith;
				break;
			case GenericFilterOperationEnum.LIKE_ENDSWITH:
				compareFunction = likeEndsWith;
				break;
		}
	} else if (
		filteredColumn instanceof NumberColumnModel ||
		filteredColumn instanceof TimeColumnModel ||
		filteredColumn instanceof CurrencyColumnModel ||
		filteredColumn instanceof DurationColumnModel
	) {
		switch (filter.operation) {
			case GenericFilterOperationEnum.EQUALS:
				compareFunction = arePrimitiveTypesEqual;
				break;
			case GenericFilterOperationEnum.NOT_EQUALS:
				compareFunction = arePrimitiveTypesNotEqual;
				break;
			case GenericFilterOperationEnum.GREATER_EQUAL_THAN:
				compareFunction = greaterEqual;
				break;
			case GenericFilterOperationEnum.GREATER_THAN:
				compareFunction = greater;
				break;
			case GenericFilterOperationEnum.LESS_EQUAL_THAN:
				compareFunction = lessEqual;
				break;
			case GenericFilterOperationEnum.LESS_THAN:
				compareFunction = less;
				break;
		}
	} else if (filteredColumn instanceof DateColumnModel || filteredColumn instanceof DateTimeColumnModel) {
		switch (filter.operation) {
			case GenericFilterOperationEnum.EQUALS:
				compareFunction = areDatesEqual;
				break;
			case GenericFilterOperationEnum.NOT_EQUALS:
				compareFunction = areDatesNotEqual;
				break;
			case GenericFilterOperationEnum.GREATER_EQUAL_THAN:
				compareFunction = dateGreaterEqual;
				break;
			case GenericFilterOperationEnum.GREATER_THAN:
				compareFunction = dateGreater;
				break;
			case GenericFilterOperationEnum.LESS_EQUAL_THAN:
				compareFunction = dateLessEqual;
				break;
			case GenericFilterOperationEnum.LESS_THAN:
				compareFunction = dateLess;
				break;
		}
		// Date(Time) values are Date types, but filter value is type string so converting to Date
		filterValue = new Date(filter.value);
	}
	return compareFunction(rowData[filteredColumn.id], filterValue)
}

export const filterData = (data: any[], filters: FilterType[] = [], columns: GenericColumnModel[]) => {
	if (filters.length === 0 || data.length === 0) {
		return data;
	}

	let filteredData = [...data];

	for (const filter of filters) {
		// property
		if (filter.columnId) {
			const filteredColumn = columns.find((column) => filter.columnId === column.id);
			if (!filteredColumn) {
				continue;
			}

			const afterFilterData: any[] = [];
			for (const rowData of filteredData) {
				if (doesRowSatisfy(rowData, filteredColumn, filter)) {
					afterFilterData.push(rowData);
				}
			}

			filteredData = afterFilterData;
		}
		// or filters
		else if (filter.orFilters) {
			const afterFilterData: any[] = [];

			for (const rowData of filteredData) {
				for (const orFilter of filter.orFilters) {
					const filteredColumn = columns.find((column) => orFilter.columnId === column.id);
					if (!filteredColumn) {
						continue;
					}
					if (doesRowSatisfy(rowData, filteredColumn, orFilter)) {
						afterFilterData.push(rowData);
						break;
					}
				}

				filteredData = afterFilterData;
			}
		}
	}

	return filteredData;
}

// sort methods

const sortByNumber = (data: any[], property: string) => {
	const sortedData = [...data];

	sortedData.sort((first, second) => {
		const firstValue = first[property] || 0;
		const secondValue = second[property] || 0;
		return firstValue - secondValue;
	})

	return sortedData;
}

const sortByDate = (data: any[], property: string) => {
	const sortedData = [...data];

	sortedData.sort((first, second) => {
		const firstTime = first[property] ? first[property].getTime() : 0;
		const secondTime = second[property] ? second[property].getTime() : 0;
		return firstTime - secondTime;
	})

	return sortedData;
}

const sortOptionByString = (data: any[], column: OptionGenericColumnModel<any>) => {
	const sortedData = [...data];
	const { items, getItemId, getItemText } = column;

	return sortedData.sort((a, b) => {
		const itemA = items.find(item => getItemId(item) === a[column.id]);
		const itemB = items.find(item => getItemId(item) === b[column.id]);

		const textA = itemA ? getItemText(itemA).toUpperCase() : '';
		const textB = itemB ? getItemText(itemB).toUpperCase() : '';

		if (textA < textB) {
			return -1;
		}

		if (textA > textB) {
			return 1;
		}

		return 0;
	});
}

export const sortData = (data: any[], sort: SortType | undefined, columns: GenericColumnModel[]) => {
	if (data.length === 0 || sort?.columnId === undefined) {
		return data;
	}

	const sortColumn = columns.find((column) => sort.columnId === column.id);

	if (sortColumn === undefined) {
		return data;
	}

	let sortedData: any[] = [];

	if (
		sortColumn instanceof OptionColumnModel ||
		sortColumn instanceof OptionsColumnModel ||
		sortColumn instanceof MapOptionColumnModel
	) {
		sortedData = sortOptionByString(data, sortColumn);
	} else if (
		sortColumn instanceof BooleanColumnModel ||
		sortColumn instanceof NumberColumnModel ||
		sortColumn instanceof TimeColumnModel ||
		sortColumn instanceof CurrencyColumnModel ||
		sortColumn instanceof DurationColumnModel ||
		sortColumn instanceof FormattedReferenceColumnModel
	) {
		sortedData = sortByNumber(data, sortColumn.id);
	} else if (sortColumn instanceof DateColumnModel || sortColumn instanceof DateTimeColumnModel) {
		sortedData = sortByDate(data, sortColumn.id);
	} else if (sortColumn instanceof StringColumnModel) {
		sortedData = sortByString(data, sortColumn.id);
	}

	return sort.isAsc ? sortedData : sortedData.reverse();
}

// pagination methods

export const paginateData = (data: any[], offset: number = 0, limit: number = defaultPaginationLimit) => {
	return data.slice(offset, offset + limit);
}
