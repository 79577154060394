export const capitalizeFirstLetter = (value: string | undefined) => {
	if (!value) {
		return value;
	}

	return value.charAt(0).toUpperCase() + value.slice(1);
}

export const sortByString = (values: any[], propName: string) => {
	return values.sort((a, b) => {
		// undefined is making some issues, so setting empty string instead
		const aStr = (a[propName] || '').toUpperCase();
		const bStr = (b[propName] || '').toUpperCase();

		if (aStr < bStr) {
			return -1;
		}
		if (aStr > bStr) {
			return 1;
		}

		return 0;
	});
}

export const convertHtmlToText = (html: string | undefined) => {
	if (!html) {
		return '';
	}

	const parsedString = new DOMParser().parseFromString(html, 'text/html');
	return parsedString.body.textContent || '';
}

// replaces {{number}} with specific args
export const replacePlaceholders = (inputString: string, args: string[] = []) => {
	return inputString.replace(/{{(\d+)}}/g, (_, index) => {
		return args[parseInt(index, 10)] || '';
	})
}
