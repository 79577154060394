import { CurrencyClient, IdNameSymbolResponse } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils'
import store from 'base/reducer/store';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';

export const getCurrenciesAction = () => {
	const client = new CurrencyClient();
	return client.getCurrencies();
}

export const persistCurrenciesAction = async () => {
	const state = store.getState();

	if (state.persistedCurrency.fetching) {
		return;
	}

	store.dispatch(setCurrenciesFetchingAction(true));

	const response = await tryCatchJsonByAction(getCurrenciesAction);
	if (response.success) {
		store.dispatch(setCurrenciesAction(response.items || []));
	} else {
		store.dispatch(setCurrenciesFetchingAction(false));
	}
}

// redux

export const CURRENCIES_SET = 'CURRENCIES_SET';
export const CURRENCIES_FETCHING = 'CURRENCIES_FETCHING';

const setCurrenciesFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: CURRENCIES_FETCHING,
		fetching
	}
}

const setCurrenciesAction = (items: IdNameSymbolResponse[]): PersistItemsActionType<IdNameSymbolResponse> => {
	return {
		type: CURRENCIES_SET,
		items
	}
}
