import { memo, useCallback, useRef, useState } from 'react'
import styles from './header.module.scss'
import { FilterIcon, LockIcon } from 'components/icons/icons'
import { SortType } from '../models';
import { Translation } from 'components/Translations/Translation';
import { emptySpaceColumnId } from 'components/Table';
import { Tooltip } from '../Tooltip/Tooltip';
import { defaultTooltipState } from '../Body/Cell';

const sortElementId = 'header_cell_sort';

type Props = {
	// from column
	id: string // column id
	title: string
	disableSort?: boolean

	// additional props
	sort?: SortType
	setSort?: (columnId: string | undefined) => void
	hasFilter: boolean
	isFilterActive: boolean
	onFilter(columnId: string): void
	columnWidth: number
	frozen?: boolean
}

const CellWithoutMemo = ({ id, title, disableSort, sort, setSort, hasFilter, isFilterActive, onFilter, columnWidth, frozen }: Props) => {
	const contentRef = useRef<HTMLDivElement>(null);
	const [hover, setHover] = useState(false);
	const [tooltipState, setTooltipState] = useState(defaultTooltipState);

	let arrowClassName = '';

	if (sort?.columnId === id) {
		arrowClassName = `${styles[sort.isAsc ? 'ascending' : 'descending']} ${styles.active_sort}`;
	}

	const mouseOverCallback = useCallback(
		(e: any) => {
			if (e.target.id !== sortElementId) {
				if (hasFilter) {
					setHover(true);
				}

				const content = contentRef.current;
				if (!content) {
					return;
				}
				const tooltipX = e.clientX + 12;
				const tooltipY = e.clientY + 12;

				const contentWidth = content.scrollWidth;
				const contentHeight = content.scrollHeight;
				const screenWidth = window.innerWidth;
				const screenHeight = window.innerHeight;

				let adjustedX = tooltipX;
				let adjustedY = tooltipY;

				// Adjust position if tooltip overflow right
				if (tooltipX + contentWidth > screenWidth) {
					adjustedX = screenWidth - contentWidth - 12;
				}
				// Adjust position if tooltip overflow bottom
				if (tooltipY + contentHeight > screenHeight) {
					adjustedY = screenHeight - contentHeight - 12;
				}

				if (content.scrollWidth > content.clientWidth) {
					setTooltipState({
						visible: true,
						position: { x: adjustedX, y: adjustedY }
					});
				}
			}
		},
		[hasFilter]
	)

	const mouseOutCallback = useCallback(
		() => {
			setHover(false);
			setTooltipState(defaultTooltipState);
		},
		[]
	)

	const onSortCallback = useCallback(
		(e: any) => {
			e.stopPropagation();
			setSort && setSort(id);
		},
		[setSort, id]
	)

	const onFilterClickCallback = useCallback(
		() => {
			hasFilter && onFilter(id);
		},
		[id, hasFilter, onFilter]
	)

	const className = `${styles.cell} ${hover ? styles.hover : ''} ${frozen ? styles.frozen : ''}`;

	return (
		<div
			className={className}
			data-type='cell'
			data-header='true'
			style={{ width: `${columnWidth}px` }}
			onMouseOver={mouseOverCallback}
			onMouseOut={mouseOutCallback}
			onClick={onFilterClickCallback}
		>
			<div
				ref={contentRef}
				className={`${styles.inner} ${!disableSort ? styles.inner_with_sort : ''} ${isFilterActive ? styles.inner_with_filter : ''}`}
				data-columnid={id}
			>
				{frozen && id !== emptySpaceColumnId &&
					<LockIcon width={10} height={10} fill={'currentColor'} style={{ marginRight: '6px', marginBottom: '3px' }} />
				}
				<span className={styles.title}>
					<Translation i18n={title} />
				</span>
				{isFilterActive &&
					<div className={styles.filter}>
						<FilterIcon width={12} height={12} stroke='currentColor' />
					</div>
				}
				{!disableSort &&
					<div id={sortElementId} className={`${styles.sort} ${arrowClassName}`} onClick={onSortCallback} />
				}
				{tooltipState.visible &&
					<Tooltip
						content={title}
						topPosition={tooltipState.position.y}
						leftPosition={tooltipState.position.x}
						withTranslation
					/>
				}
			</div>
		</div>
	)
}

export const Cell = memo(CellWithoutMemo);
