import store from 'base/reducer/store';
import { FetchingProjectItemsActionType, PersistProjectItemsActionType } from 'features/Persist/actionTypes';
import { getTenantId } from 'features/Tenant/tenantUtils';
import { ProjectConfigDeltaRequest, ProjectIsActiveResponse, ProjectTeamClient } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';

export const getProjectTeamsAction = (projectId: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectTeamClient();
	return client.getAllByProjectId(tenantId, projectId);
}

export const updateProjectTeamsAction = (projectId: number, model: ProjectConfigDeltaRequest) => {
	const tenantId = getTenantId()!;
	const client = new ProjectTeamClient();
	return client.update(tenantId, projectId, model);
}

export const persistProjectTeamsAction = async (projectId: number) => {
	const state = store.getState();

	if (state.persistedProjectTeam.projectMap[projectId]?.fetching) {
		return;
	}

	store.dispatch(setProjectTeamsFetchingAction(true, projectId));

	const bindedAction = getProjectTeamsAction.bind(null, projectId);
	const response = await tryCatchJsonByAction(bindedAction);

	if (response.success && response.items) {
		store.dispatch(setProjectTeamsAction(projectId, response.items || []));
	} else {
		store.dispatch(setProjectTeamsFetchingAction(false, projectId));
	}
}

// persist project teams redux

export const PROJECT_TEAMS_FETCHING = 'PROJECT_TEAMS_FETCHING';
export const PROJECT_TEAMS_SET = 'PROJECT_TEAMS_SET';

const setProjectTeamsFetchingAction = (fetching: boolean, projectId: number): FetchingProjectItemsActionType => {
	return {
		type: PROJECT_TEAMS_FETCHING,
		fetching,
		projectId
	}
}

const setProjectTeamsAction = (projectId: number, items: ProjectIsActiveResponse[]): PersistProjectItemsActionType<ProjectIsActiveResponse> => {
	return {
		type: PROJECT_TEAMS_SET,
		items,
		projectId
	}
}
