import { useCallback, useMemo } from 'react';
import DatePickerLib, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CalendarIcon } from 'components/icons/icons';
import { ControlsCommonProps } from '../../fields';
import styles from './datePicker.module.scss';
import { OverlaySpinner } from 'components/Spinner';
import { useGetDatePatternString, useGetTimePatternString } from 'utils/dateTimeUtils';
import { enUS, srLatn } from 'date-fns/locale'
import { getLangCode, useGetTranslation } from 'components/Translations/translationUtils';
import { languages } from 'components/Translations/TranslationsProvider';
import { LanguagesEnum } from 'services/tenantManagementService';

registerLocale(languages[LanguagesEnum.English].locale, enUS);
registerLocale(languages[LanguagesEnum.Serbian].locale, srLatn);

export type DatePickerProps = ControlsCommonProps<Date> & {
	minDate?: Date
	maxDate?: Date
	showTime?: boolean
	dateFormat?: string
	focus?: boolean
	size?: 'medium' | 'small'
	loading?: boolean
};

export const DatePicker = ({
	value,
	onChange,
	onBlur,
	disabled,
	minDate,
	maxDate,
	showTime,
	focus = false,
	size = 'medium',
	loading
}: DatePickerProps) => {
	const getTranslation = useGetTranslation();
	const datePattern = useGetDatePatternString();
	// library works with "aa" instead of "tt"
	const timePattern = useGetTimePatternString().replace('tt', 'aa');

	const dateTimePattern = useMemo(
		() => showTime ? `${datePattern} ${timePattern}`: datePattern,
		[datePattern, timePattern, showTime]
	)

	const onChangeCallback = useCallback(
		(date) => onChange && onChange(date),
		[onChange]
	)

	const handleCalendarClose = useCallback(
		() => onBlur && onBlur(),
		[onBlur]
	)

	const timeLabel = useMemo(
		() => getTranslation('i18n.label.time'),
		[getTranslation]
	)

	return (
		<div className={`${styles.container} ${size === 'small' ? styles.small : ''}`}>
			<DatePickerLib
				className={`${styles.date_picker} ${size === 'small' ? styles.small : ''}`}
				selected={value}
				onChange={onChangeCallback}
				onCalendarClose={handleCalendarClose}
				onBlur={onBlur}
				disabled={disabled || loading}
				minDate={minDate}
				maxDate={maxDate}
				showMonthDropdown
				showYearDropdown
				yearDropdownItemNumber={3}
				showTimeSelect={showTime}
				dateFormat={dateTimePattern}
				timeFormat={timePattern}
				autoFocus={focus}
				portalId={'datePickerPortalId'}
				timeCaption={timeLabel}
				locale={getLangCode()}
			/>
			<div className={`${styles.icon} ${size === 'small' ? styles.small : ''}`}>
				<CalendarIcon width={16} height={16} fill='currentColor' />
			</div>
			{/* loading */}
			{loading && <OverlaySpinner useBrandColor />}
		</div>
	)
}
