import { useCallback, useMemo } from 'react'
import styles from './header.module.scss'
import { GenericColumnModel, FilterSortPageType, InteractionManager } from '../models'
import { Cell } from './Cell'
import { ColumnWidthMap } from '../CustomTable'
import { findFilter } from '../Filter/filtersHelper'
import { ReordableColumns } from 'components/DragNDrop'
import { noop } from 'utils/commonHelper'

export type HeaderGroup = {
	id: string
	title: string
	columnIds: string[]
}

type Props = {
	columns: GenericColumnModel[]
	columnWidthMap: ColumnWidthMap
	filterSortPage: FilterSortPageType
	onFilter: (columnId: string) => void
	disabledReorder: boolean
	additionalHeaderRow?: HeaderGroup[]
	interactionManager: InteractionManager
}

export const Header = ({ columns, columnWidthMap, filterSortPage, onFilter, disabledReorder, additionalHeaderRow, interactionManager }: Props) => {
	const frozenStartColumnIdsMemo = useMemo(
		() => columns.filter(column => column.frozen === 'start').map(x => x.id),
		[columns]
	)

	const draggableColumnIdsMemo = useMemo(
		() => columns.filter(column => !column.frozen).map(x => x.id),
		[columns]
	)

	const frozenEndColumnIdsMemo = useMemo(
		() => columns.filter(column => column.frozen === 'end').map(x => x.id),
		[columns]
	)

	const cellProps = useCallback(
		(column: GenericColumnModel) => {
			return {
				...column,
				sort: filterSortPage.sort,
				setSort: interactionManager.sort,
				hasFilter: !column.disableFilter,
				isFilterActive: findFilter(filterSortPage.filters, column.id) !== undefined,
				onFilter: onFilter,
				columnWidth: columnWidthMap[column.id],
				frozen: !!column.frozen
			}
		},
		[columnWidthMap, filterSortPage, onFilter, interactionManager]
	)

	const renderCellsContent = useCallback(
		(id: string) => {
			const column = columns.find(col => col.id === id);

			if (!column) {
				return;
			}

			return <Cell key={column.id} {...cellProps(column)} />
		},
		[columns, cellProps]
	)

	const renderAdditionalHeaderContent = useCallback(
		() => {
			if (!additionalHeaderRow) {
				return <></>;
			}

			return (
				<div className={styles.row}>
					{frozenStartColumnIdsMemo.length > 0 &&
						frozenStartColumnIdsMemo.map(columnId =>
							<div
								key={`header_group_${columnId}`}
								className={`${styles.cell} ${styles.frozen}`}
								data-type='cell'
								data-header='true'
								style={{ width: columnWidthMap[columnId] }}
							/>
						)
					}
					<div className={styles.cells_wrapper}>
						{additionalHeaderRow.map(group =>
							<div
								key={group.id}
								className={styles.cell}
								data-type='cell'
								data-header='true'
								style={{ width: group.columnIds.reduce((acc, columnId) => acc + (columnWidthMap[columnId] || 0), 0) }}
							>
								{group.title}
							</div>
						)}
					</div>
				</div>
			)
		},
		[additionalHeaderRow, frozenStartColumnIdsMemo, columnWidthMap]
	)

	return (
		<div className={`${styles.container} ${additionalHeaderRow ? styles.column_direction : ''}`}>
			{renderAdditionalHeaderContent()}
			<div className={styles.row}>
				{frozenStartColumnIdsMemo.length > 0 && frozenStartColumnIdsMemo.map(id => renderCellsContent(id))}
				<div className={styles.cells_wrapper}>
					<ReordableColumns
						frozenStartElementIds={frozenStartColumnIdsMemo}
						draggableElementIds={draggableColumnIdsMemo}
						frozenEndElementIds={frozenEndColumnIdsMemo}
						onReorder={interactionManager.reorderColumn || noop}
						renderItem={renderCellsContent}
						disabled={disabledReorder}
					/>
				</div>
				{frozenEndColumnIdsMemo.length > 0 && frozenEndColumnIdsMemo.map(id => renderCellsContent(id))}
			</div>
		</div>
	)
}
