import { FetchingProjectItemsActionType, PersistProjectItemsActionType } from 'features/Persist/actionTypes';
import { PersistProjectItemsReducer } from 'features/Persist/reducerTypes';
import { ProjectTeamMemberResponse } from 'services/tenantManagementService';
import { TEAM_MEMBERS_FETCHING, TEAM_MEMBERS_SET } from './action';

export const teamMembersReducer = (state = new PersistProjectItemsReducer<ProjectTeamMemberResponse>(), action: PersistProjectItemsActionType<ProjectTeamMemberResponse> | FetchingProjectItemsActionType): PersistProjectItemsReducer<ProjectTeamMemberResponse> => {
	switch (action.type) {
		case TEAM_MEMBERS_FETCHING: {
			const { fetching, projectId } = action as FetchingProjectItemsActionType;

			return {
				projectMap: {
					...state.projectMap,
					[projectId]: {
						isInitialized: state.projectMap[projectId]?.isInitialized || false,
						fetching,
						items: state.projectMap[projectId]?.items || [],
						itemsMap: state.projectMap[projectId]?.itemsMap || {}
					}
				}
			}
		}
		case TEAM_MEMBERS_SET:
			const { items, projectId } = action as PersistProjectItemsActionType<ProjectTeamMemberResponse>;

			const itemsMap = items.reduce(
				(map: { [id: number]: ProjectTeamMemberResponse }, item) => {
					map[item.id] = item;
					return map;
				},
				{}
			)

			const projectMap = {
				...state.projectMap,
				[projectId]: {
					isInitialized: true,
					fetching: false,
					items,
					itemsMap
				}
			}

			return {
				projectMap
			}
		default:
			return state;
	}
};
