export class PersistItemsReducer<P> {
	// if first fetch was finished and there are some data in reducer
	isInitialized: boolean = false;
	// is fetching in progress
	fetching: boolean = false;

	items: P[] = [];

	itemsMap: { [id: number]: P | undefined } = {};
}

export class PersistActiveItemsReducer<P> extends PersistItemsReducer<P> {
	// items array with isActive = true
	activeItems: P[] = [];

	// itemsMap with isActive = true
	activeItemsMap: { [id: number]: P | undefined } = {};
}

export class PersistValueReducer<P> {
	// if first fetch was finished and there are some data in reducer
	isInitialized: boolean = false;
	// is fetching in progress
	fetching: boolean = false;

	value: P = {} as P;
}

export class PersistProjectItemsReducer<P> {
	projectMap: {
		[id: number]: PersistItemsReducer<P> | undefined
	} = {};
}

export class PersistProjectValueReducer<P> {
	projectMap: {
		[id: number]: PersistValueReducer<P> | undefined
	} = {};
}
