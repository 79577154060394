import { toast, ToastContent, TypeOptions, ToastOptions } from 'react-toastify';
import NotificationComponent from './NotificationComponent';
import { CheckIcon, InfoIcon, WarningIcon } from 'components/icons/icons';
import { NotificationContent } from './NotificationContent';

type NotificationTypeOptions = {
	type: TypeOptions
}

type FunctionType = (message: string, customOptions?: ToastOptions) => React.ReactText;

type NotificationModel = {
	info: FunctionType
	success: FunctionType
	warning: FunctionType
	error: FunctionType
}

const mergeOptions = (typeOptions: NotificationTypeOptions, customOptions?: ToastOptions) => {
	const defaultOptions = {
		hideProgressBar: true,
		draggable: false
	}

	return { ...defaultOptions, ...customOptions, ...typeOptions };
};

const toastMessage = (
	toastComponent: ToastContent,
	typeOptions: NotificationTypeOptions,
	customOptions?: ToastOptions
) => {
	const options = mergeOptions(typeOptions, customOptions);
	return toast(toastComponent, options);
};

const notifications: NotificationModel = {
	info: (message: string, customOptions?: ToastOptions) => {
		const typeOptions = { type: toast.TYPE.INFO };
		return toastMessage(
			<NotificationComponent message={message} icon='info' />,
			typeOptions,
			customOptions
		);
	},
	success: (message: string, customOptions?: ToastOptions) => {
		const typeOptions = { type: toast.TYPE.SUCCESS };
		return toastMessage(
			<NotificationComponent message={message} icon='success' />,
			typeOptions,
			customOptions
		);
	},
	warning: (message: string, customOptions?: ToastOptions) => {
		const typeOptions = { type: toast.TYPE.WARNING, autoClose: false };
		return toastMessage(
			<NotificationComponent message={message} icon='warning' />,
			typeOptions,
			customOptions
		);
	},
	error: (message: string, customOptions?: ToastOptions) => {
		const typeOptions = { type: toast.TYPE.ERROR, autoClose: false };
		return toastMessage(
			<NotificationComponent message={message} icon='error' />,
			typeOptions,
			customOptions
		);
	},
};

export default notifications;

// new implementation, old one will be DEPRECATED:

const defaultOptions = {
	hideProgressBar: true,
	draggable: false
}

type NotificationArg = {
	message: string
	i18nArgs?: string[]
	route?: string
	options?: ToastOptions
}

type Notification = {
	info: (arg: NotificationArg) => void
	success: (arg: NotificationArg) => void
	warning: (arg: NotificationArg) => void
	error: (arg: NotificationArg) => void
}

export const notification: Notification = {
	info: ({ options, ...props }) => {
		toast(
			<NotificationContent {...props} Icon={InfoIcon} />,
			{
				...defaultOptions,
				type: toast.TYPE.INFO,
				...options
			}
		);
	},
	success: ({ options, ...props }) => {
		toast(
			<NotificationContent {...props} Icon={CheckIcon} />,
			{
				...defaultOptions,
				type: toast.TYPE.SUCCESS,
				...options
			}
		);
	},
	warning: ({ options, ...props }) => {
		toast(
			<NotificationContent {...props} Icon={WarningIcon} />,
			{
				...defaultOptions,
				type: toast.TYPE.WARNING,
				autoClose: false,
				...options
			}
		);
	},
	error: ({ options, ...props }) => {
		toast(
			<NotificationContent {...props} Icon={WarningIcon} />,
			{
				...defaultOptions,
				type: toast.TYPE.ERROR,
				autoClose: false,
				...options
			}
		);
	}
};
