import { FetchingProjectValueActionType, PersistProjectValueActionType } from 'features/Persist/actionTypes';
import { PersistProjectValueReducer } from 'features/Persist/reducerTypes';
import { TimesheetRequiredFieldsResponse } from 'services/tenantManagementService';
import { TIMESHEET_REQUIRED_FIELDS_FETCHING, TIMESHEET_REQUIRED_FIELDS_SET } from './action';

export const timesheetRequiredFieldsReducer = (state = new PersistProjectValueReducer<TimesheetRequiredFieldsResponse>(), action: PersistProjectValueActionType<TimesheetRequiredFieldsResponse> | FetchingProjectValueActionType): PersistProjectValueReducer<TimesheetRequiredFieldsResponse> => {
	switch (action.type) {
		case TIMESHEET_REQUIRED_FIELDS_FETCHING: {
			// fetching is only for this projectId
			const { fetching, projectId } = action as FetchingProjectValueActionType;

			return {
				projectMap: {
					...state.projectMap,
					[projectId]: {
						isInitialized: state.projectMap[projectId]?.isInitialized || false,
						fetching,
						value: state.projectMap[projectId]?.value || new TimesheetRequiredFieldsResponse()
					}
				}
			}
		}
		case TIMESHEET_REQUIRED_FIELDS_SET: {
			const { value, projectId } = action as PersistProjectValueActionType<TimesheetRequiredFieldsResponse>;

			const projectMap = {
				...state.projectMap,
				[projectId]: {
					isInitialized: true,
					fetching: false,
					value
				}
			}

			return {
				projectMap
			}
		}
		default:
			return state;
	}
};
