// redux

import store from 'base/reducer/store';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';
import { DateTimePatternClient, DateTimePatternResponse } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';

export const getDatePatternsAction = () => {
	const client = new DateTimePatternClient();
	return client.getDatePatterns();
}

export const getTimePatternsAction = () => {
	const client = new DateTimePatternClient();
	return client.getTimePatterns();
}

export const persistDatePatternsAction = async () => {
	const state = store.getState();

	if (state.persistedDatePattern.fetching) {
		return;
	}

	store.dispatch(setDatePatternFetchingAction(true));

	const response = await tryCatchJsonByAction(getDatePatternsAction);
	if (response.success) {
		store.dispatch(setDatePatternAction(response.items || []));
	} else {
		store.dispatch(setDatePatternFetchingAction(false));
	}
}

export const persistTimePatternsAction = async () => {
	const state = store.getState();

	if (state.persistedTimePattern.fetching) {
		return;
	}

	store.dispatch(setTimePatternFetchingAction(true));

	const response = await tryCatchJsonByAction(getTimePatternsAction);
	if (response.success) {
		store.dispatch(setTimePatternAction(response.items || []));
	} else {
		store.dispatch(setTimePatternFetchingAction(false));
	}
}

export const DATE_PATTERN_FETCHING = 'DATE_PATTERN_FETCHING';
export const DATE_PATTERN_SET = 'DATE_PATTERN_SET';

export const TIME_PATTERN_FETCHING = 'TIME_PATTERN_FETCHING';
export const TIME_PATTERN_SET = 'TIME_PATTERN_SET';

const setDatePatternFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: DATE_PATTERN_FETCHING,
		fetching
	}
}

const setDatePatternAction = (items: DateTimePatternResponse[]): PersistItemsActionType<DateTimePatternResponse> =>  {
	return {
		type: DATE_PATTERN_SET,
		items
	}
}

const setTimePatternFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: TIME_PATTERN_FETCHING,
		fetching
	}
}

const setTimePatternAction = (items: DateTimePatternResponse[]): PersistItemsActionType<DateTimePatternResponse> =>  {
	return {
		type: TIME_PATTERN_SET,
		items
	}
}
