import { languages, useTranslations, useTranslationsDispatch } from "components/Translations/TranslationsProvider";
import { useCallback, useEffect } from 'react';
import { LanguagesEnum } from "services/tenantManagementService";
import appSettings from "settings/appSettings";
import { replacePlaceholders } from 'utils/stringUtil';

const LOCALE = 'locale';

export const setLocale = (locale: LanguagesEnum) => {
	localStorage.setItem(LOCALE, locale);
}

export const getLocale = (): LanguagesEnum => {
	const locale = localStorage.getItem(LOCALE);
	if (locale) {
		return locale as LanguagesEnum;
	}

	return LanguagesEnum.English;
}

export const getLangCode = () => {
	const locale = getLocale();
	return languages[locale].locale;
}

export const getLanguageFromLocale = (locale: LanguagesEnum | undefined) => {
	switch (locale) {
		case LanguagesEnum.Serbian:
			return languages[LanguagesEnum.Serbian];
		case LanguagesEnum.English:
		default:
			return languages[LanguagesEnum.English];
	}
}

export const useLoadTranslation = () => {
	const dispatch = useTranslationsDispatch();
	const { loadedTranslations, loadingInProgress } = useTranslations();

	return useCallback(
		async (routeId: string) => {
			if (loadedTranslations[routeId] || loadingInProgress.has(routeId)) {
				return;
			}

			dispatch({ type: 'LOAD_TRANSLATION', payload: routeId });
			const locale = getLocale();
			setLocale(locale);
			const languageCode = languages[locale].locale;
			try {
				const apiUrl = appSettings.getApiUrl();
				const translationsText = await (await fetch(`${apiUrl}/translations/${languageCode}/${routeId}.json`)).text();
				const translations = JSON.parse(translationsText);
				dispatch({ type: 'SET_TRANSLATION_LOADED', payload: { routeId, translations } });
			} catch (error) {
				console.error(`Error loading translation for ${routeId}:`, error);
			}
		},
		[dispatch, loadedTranslations, loadingInProgress]
	)
}

export const useLoadTranslationEffect = (routeId: string) => {
	const loadedTranslation = useLoadTranslation();
	useEffect(
		() => {
			loadedTranslation(routeId);
		},
		[routeId, loadedTranslation]
	)
}

export const isNotTranslated = (value: string) => {
	return value.startsWith('i18n');
}

export const useGetTranslation = () => {
	const { translations } = useTranslations();
	return useCallback(
		(i18n: string | undefined, args?: string[]) => {
			if (i18n === undefined) {
				return ''
			}

			const translation = translations[i18n];

			if (translation) {
				const translatedText = replacePlaceholders(translation, args);
				return translatedText;
			}

			return i18n;
		},
		[translations]
	)
}
