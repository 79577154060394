import { IconType } from 'components/icons/icons';
import { Translation } from 'components/Translations/Translation';
import styles from './notificationComponent.module.scss';
import 'react-toastify/dist/ReactToastify.css';
import './toastrOverride.scss';

type Props = {
	message: string
	i18nArgs?: string[]
	route?: string
	Icon: IconType
}

export const NotificationContent = ({ message, i18nArgs, route, Icon }: Props) => {
	const messageContent = <Translation i18n={message} args={i18nArgs} />

	let content = <span className={styles.message}>{messageContent}</span>
	if (route) {
		content = <a href={route} className={styles.message_with_route}>{messageContent}</a>
	}

	return (
		<div className={styles.container}>
			<span className={styles.icon}>
				<Icon
					width={22}
					height={22}
					fill='currentColor'
				/>
			</span>
			{content}
		</div>
	)
}
