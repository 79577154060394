import React from 'react';
import ReactDOM from 'react-dom';

import 'normalize.css';
import 'assets/styles/reset.scss';
import 'assets/styles/scrollbar.scss'; // separated from reset because storybook is not showing scroll bar with this styles

import 'assets/styles/bootstrap.override.css'

import AppContainer from 'containers/AppContainer';
import errorReportingService from 'services/errorReportingService';

errorReportingService.init();

ReactDOM.render(
	<React.StrictMode>
		<AppContainer />
	</React.StrictMode>,
	document.getElementById('root')
);
