import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Body } from "./Body/Body";
import { HeaderGroup, Header } from "./Header/Header";
import { GenericColumnModel, FilterSortPageType, FilterType, InteractionManager, FormatterCell } from "./models";
import styles from './customTable.module.scss'
import { Frozen } from "./Separators/Frozen";
import { Pagination } from "./Pagination/Pagination";
import { Resize } from "./Separators/Resize";
import { Filter } from "./Filter/Filter";
import { columnMinWidth, defaultPaginationLimit } from "./consts";
import { getColumnMaxInitialWidth } from "./helpers";
import { Footer } from "./Footer/Footer";
import { OverlaySpinner } from "components/Spinner";

type Props = {
	columns: GenericColumnModel[]
	rowsData: any[]
	footerRowsData?: any[]
	cellEdited?: (oldRowData: any, columnId: string, newValue: any) => Promise<void>
	/** Every next refetch so Spinner can be displayed */
	refetching?: boolean

	pagination?: {
		offset?: number // starting index
		limit?: number // max number of rows
		count: number // length of all items
		onChange: (offset: number) => void
	}
	compact?: boolean

	filterSortPage: FilterSortPageType
	disabledReorder: boolean

	/**
	 * - fill: Table will resize the columns and expand each column equally to fill up width of the table. However, if column has set width, it will keep that specific width.
	 * - If fill is not set, fitData will be used by default. Table will resize the columns to fit their data, and ensure that rows take up the full width of the table.
	*/
	fill?: boolean

	/** Render additional header row above the default header.
	 * This row includes grouped columns, with each group spanning width of the grouped columns.
	*/
	additionalHeaderRow?: HeaderGroup[]
	expand?: (rowData: any) => void
	collapse?: (rowData: any) => void
	onGroupClick?: (groupId: string) => void

	interactionManager: InteractionManager
}

export type ColumnWidthMap = { [columnId: string]: number }

export const CustomTable = ({
	columns, rowsData, footerRowsData, cellEdited, refetching,
	pagination, compact, filterSortPage, disabledReorder, fill, additionalHeaderRow, expand, collapse, onGroupClick, interactionManager
}: Props) => {
	const containerRef = useRef<HTMLDivElement | null>(null);
	const [columnWidthMap, setColumnWidthMap] = useState<ColumnWidthMap>({});

	const [activeFilterColumn, setActiveFilterColumn] = useState<GenericColumnModel>();

	const [containerWidth, setContainerWidth] = useState<number>(containerRef.current?.offsetWidth || 0);

	const calculateColumnWidthsCallback = useCallback(
		() => {
			const container = containerRef.current;

			if (!container) {
				return;
			}

			const maxColumnWidthMap: ColumnWidthMap = {};

			for (let i = 0; i < columns.length; i++) {
				const column = columns[i];
				const columnId = column.id;

				// if width is set, use it directly
				if (column.width) {
					maxColumnWidthMap[columnId] = column.width;
				} else {
					// if width is not set, get all the cells that belong to this column and calculate which cell width is the biggest
					const innerCells = container.querySelectorAll(`[data-columnid="${columnId}"]`);
					const maxInitialWidth = getColumnMaxInitialWidth(column);

					maxColumnWidthMap[columnId] = columnMinWidth;

					for (let j = 0; j < innerCells.length; j++) {
						// calculate cellWidth as content width + cell horizontal padding
						const innerCell = innerCells[j];
						const cell = innerCell.closest('[data-type="cell"]')!;
						const isBodyCell = !!innerCell.closest('[data-body="true"]');
						const paddingLeft = window.getComputedStyle(cell, null).getPropertyValue('padding-left');
						const paddingRight = window.getComputedStyle(cell, null).getPropertyValue('padding-right');
						let cellWidth = innerCell.getBoundingClientRect().width + parseInt(paddingLeft) + parseInt(paddingRight);

						// if cell is editable it contains control that can have some icon, so we leave space for icon
						if (isBodyCell && column.editable) {
							const formatterCell: FormatterCell = {
								rowData: rowsData[j - 1], // - 1 because innerCells include one header cell (header, body, footer)
								value: rowsData[j - 1][columnId],
								columnId
							}
							const isEditable = column.editable(formatterCell);

							if (isEditable) {
								// TODO:code_improvement random number because of arrow/time_icon/date_icon/some_paddings, and it shouldn't be hardcoded here
								cellWidth += 28;
							}
						}

						// if cellWidth is bigger than maxInitialWidth, then calculation is finished and we use maxInitialWidth value as Column width
						if (cellWidth >= maxInitialWidth) {
							maxColumnWidthMap[columnId] = maxInitialWidth;
							break;
						}

						if (cellWidth > maxColumnWidthMap[columnId]) {
							maxColumnWidthMap[columnId] = Math.ceil(cellWidth);
						}
					}
				}
			}

			const totalColumnWidth = columns.reduce((total, column) => total + maxColumnWidthMap[column.id], 0);

			// if fill === true and there is more empty space on the right, increase column widths so there is no empty space
			if (fill && totalColumnWidth < containerWidth) {
				// TODO:code_improvements when fill and table is smaller than container,
				// the last column resize line is out of the bounds and horizontal scroll appears,
				// so we add -3 which represents half width of resize handle
				const extraSpace = containerWidth - totalColumnWidth - 3;
				// increase column widths equally, except ones that have width set (GenericColumnModel.width)
				const affectedColumns = columns.filter((column) => !column.width);
				for (const column of affectedColumns) {
					const columnId = column.id;
					maxColumnWidthMap[columnId] = maxColumnWidthMap[columnId] + Math.floor(extraSpace / affectedColumns.length);
				}
			}

			setColumnWidthMap(maxColumnWidthMap);
		},
		[columns, rowsData, containerWidth, fill]
	)

	useEffect(() => {
		const container = containerRef.current;

		if (!container) {
			return;
		}

		const resizeObserver = new ResizeObserver(() => {
			setContainerWidth(container.clientWidth);
		});

		resizeObserver.observe(container);

		return () => {
			resizeObserver.disconnect();
		};
	}, []);

	useLayoutEffect(
		() => {
			calculateColumnWidthsCallback();
		},
		[calculateColumnWidthsCallback]
	)

	const setFiltersCallback = useCallback(
		(newFilters: FilterType[]) => {
			interactionManager.filter?.(newFilters);
			setActiveFilterColumn(undefined);
		},
		[interactionManager]
	)

	const cancelFilterCallback = useCallback(
		() => setActiveFilterColumn(undefined),
		[]
	)

	const onFilterClickCallback = useCallback(
		(columnId: string) => {
			const selectedColumn = columns.find((column) => column.id === columnId);
			setActiveFilterColumn(selectedColumn);
		},
		[columns]
	)

	return (
		<div>
			<Filter
				filters={filterSortPage.filters || []}
				column={activeFilterColumn}
				columns={columns}
				onSave={setFiltersCallback}
				onCancel={cancelFilterCallback}
			/>
			<div ref={containerRef} className={styles.container}>
				<Header
					columns={columns}
					columnWidthMap={columnWidthMap}
					filterSortPage={filterSortPage}
					onFilter={onFilterClickCallback}
					disabledReorder={disabledReorder}
					additionalHeaderRow={additionalHeaderRow}
					interactionManager={interactionManager}
				/>
				<Body
					columns={columns}
					rowsData={rowsData}
					columnWidthMap={columnWidthMap}
					cellEdited={cellEdited}
					expand={expand}
					collapse={collapse}
					onGroupClick={onGroupClick}
					interactionManager={interactionManager}
				/>
				{rowsData.length > 0 &&
					<Footer
						columns={columns}
						columnWidthMap={columnWidthMap}
						footerRowsData={footerRowsData}
					/>
				}
				<Resize
					columns={columns}
					columnWidthMap={columnWidthMap}
					setColumnWidthMap={setColumnWidthMap}
					interactionManager={interactionManager}
				/>
				<Frozen
					columns={columns}
					columnWidthMap={columnWidthMap}
					containerRef={containerRef}
				/>
			</div>
			{pagination &&
				<Pagination
					offset={pagination.offset || 0}
					limit={pagination.limit || defaultPaginationLimit}
					count={pagination.count}
					onChange={pagination.onChange}
					compact={compact}
				/>
			}
			{refetching &&
				<OverlaySpinner
					size={60}
					useBrandColor
					withBackground
				/>
			}
		</div>
	)
}
