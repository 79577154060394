import { AttachmentColumnModel, BooleanColumnModel, CurrencyColumnModel, DateColumnModel, DateTimeColumnModel, DurationColumnModel, FormattedReferenceColumnModel, FormatterCell, GenericColumnModel, LinkColumnModel, MapOptionColumnModel, NumberColumnModel, OptionColumnModel, OptionsColumnModel, SemaphoreColumnModel, StringColumnModel, TimeColumnModel } from "../models";
import { convertToAbsoluteName, getSelectedItem, MapItem } from "components/Form/controls/MapPicker/MapPicker/helpers";
import { formatCurrencyUtil } from "utils/currencyUtils";
import { LinkCell } from "./LinkCell/LinkCell";
import { getFormatedId } from "utils/commonHelper";
import { CheckIcon, LinkIcon } from "components/icons/icons";
import { convertTicksToMinutes, formatTime, formatTimeWithoutAmOrPm, minutesToDhm, useFormatDate, useFormatDateTime } from "utils/dateTimeUtils";
import styles from './body.module.scss'
import { useCallback } from 'react';

const formattedOptionValue = (column: GenericColumnModel, formatterCell: FormatterCell) => {
	const { items, getItemId, getItemText, getItems } = column as OptionColumnModel<any>;
	const newItems = getItems ? getItems(formatterCell) : items;
	if (newItems && getItemId && getItemText) {
		const selectedOption = newItems.find((item: any) => getItemId(item) === formatterCell.value);
		return selectedOption ? getItemText(selectedOption) : formatterCell.value;
	}
	return formatterCell.value;
}

const formattedOptionsValue = (column: GenericColumnModel, formatterCell: FormatterCell) => {
	const { items, getItemId, getItemText, getItems } = column as OptionColumnModel<any>;
	const newItems = getItems ? getItems(formatterCell) : items;

	const formattedValues: any[] = [];
	for (let itemValue of formatterCell.value || []) {
		const selectedOption = newItems.find((item: any) => getItemId(item) === itemValue);
		formattedValues.push(selectedOption ? getItemText(selectedOption) : itemValue);
	}

	return formattedValues.join(', ');
}

const formatMapOption = (id: number | undefined, options: MapItem[] = []) => {
	const selectedOption = getSelectedItem(id, options);
	if (selectedOption) {
		return convertToAbsoluteName(selectedOption, options);
	}

	return '';
}

const formatCurrency = (value: number | undefined) => {
	return formatCurrencyUtil(value) || '';
}

const formattedLinkValue = (value: string | undefined, isCellEditable: boolean) => {
	return value ? <LinkCell value={value} isCellEditable={isCellEditable} type='link' /> : <></>;
}

const formattedAttachmentValue = (value: any[] | undefined, column: AttachmentColumnModel, formatterCell: FormatterCell, isCellEditable: boolean) => {
	if (!value || value.length === 0) {
		return <></>;
	}

	const downloadAttachmentFunction = column.download ?
		async (attachmentId: number) => column.download && column.download(formatterCell, attachmentId)
		: undefined;

	return (
		<LinkCell
			value={value}
			isCellEditable={isCellEditable}
			downloadAttachment={downloadAttachmentFunction}
			type='attachment'
			isSimpleAttachment={column.simple}
		/>
	)
}

const createSemaphoreElement = (value: string, column: SemaphoreColumnModel) => {
	const item = column.colors.find((item) => item.id === value);
	const color = item?.color;
	return <div className={styles.semaphore} style={{ backgroundColor: color } } />
}

const formatReferenceColumn = (value: number | number[] | undefined, column: FormattedReferenceColumnModel, formatterCell: FormatterCell, isCellEditable: boolean) => {
	if (!value) {
		return '';
	}

	const formattedValue = !Array.isArray(value) ?
		getFormatedId(column.entityPrefix, value) :
		value.map((id) => getFormatedId(column.entityPrefix, id)).join(';');

	if (column.route) {
		const route = column.route(formatterCell);

		if (column.multiLink) {
			return <LinkCell value={formattedValue} isCellEditable={isCellEditable} type='link' route={route} />;
		}

		return (
			<div className={styles.reference_link}>
				<LinkIcon className={styles.icon} width={12} height={12} fill='currentColor' />
				<a href={route} rel="noreferrer">{formattedValue}</a>
			</div>
		)
	} else {
		return formattedValue;
	}
}

const formatDurationValue = (value: number | undefined, column: DurationColumnModel) => {
	if (column.showInDhm) {
		return minutesToDhm(value || 0);
	}

	return value ? `${convertTicksToMinutes(value)} min` : '';
}

const formatTimeValue = (value: number | undefined, column: TimeColumnModel) => {
	if (column.isDuration) {
		return formatTimeWithoutAmOrPm(value);
	}

	return formatTime(value);
}

// Body cell, footer cell and Ticket History (History.tsx) use it
export const useCellFormatter = () => {
	const formatDate = useFormatDate();
	const formatDateTime = useFormatDateTime();

	return useCallback(
		(column: GenericColumnModel, formatterCell: FormatterCell, isCellEditable: boolean) => {
			const value = formatterCell.value;
			const isBooleanColumn = column instanceof BooleanColumnModel;

			if (column.formatter) {
				return column.formatter(formatterCell);
			}

			if (column instanceof FormattedReferenceColumnModel) {
				return formatReferenceColumn(value, column, formatterCell, isCellEditable);
			} else if (column instanceof SemaphoreColumnModel) {
				return createSemaphoreElement(value, column);
			} else if (column instanceof DateColumnModel) {
				return formatDate(value);
			} else if (column instanceof DateTimeColumnModel) {
				return formatDateTime(value);
			} else if (column instanceof CurrencyColumnModel) {
				return formatCurrency(value && parseFloat(value));
			} else if (column instanceof OptionsColumnModel) {
				return formattedOptionsValue(column, formatterCell);
			} else if (column instanceof OptionColumnModel) {
				return formattedOptionValue(column, formatterCell);
			} else if (column instanceof MapOptionColumnModel) {
				return formatMapOption(value && parseInt(value), column.items);
			} else if (column instanceof LinkColumnModel) {
				return formattedLinkValue(value, isCellEditable);
			} else if (column instanceof AttachmentColumnModel) {
				return formattedAttachmentValue(value, column, formatterCell, isCellEditable);
			} else if (column instanceof TimeColumnModel) {
				return formatTimeValue(value, column);
			} else if (column instanceof DurationColumnModel) {
				return formatDurationValue(value, column);
			} else if (column instanceof NumberColumnModel) {
				return value ? parseInt(value) : '';
			} else if (column instanceof StringColumnModel) {
				return value ? value.toString() : '';
			} else if (isBooleanColumn && !isCellEditable) {
				return value ? <CheckIcon width={20} height={20} fill='currentColor' /> : <></>;
			}

			return '';
		},
		[formatDate, formatDateTime]
	)

}
