import { AutoCompleteField, UpdateDependantsFunctionType } from "components/Form"
import { ProjectResponse } from "services/tenantManagementService"
import { UseProjectsAndCategoriesOptions, useProjects } from "../projectHooks"
import { EntityPrefixEnum, getFormatedId } from "utils/commonHelper"
import { useSelector } from "react-redux"
import { RootState } from "base/reducer/reducer"

type Props = UseProjectsAndCategoriesOptions & {
	id: string
	isRequired?: boolean
	disabled?: boolean
	initialProjectId?: number // if selected project changed status in meantime, it may be skipped by getProjectsForSelect filter, but we need to display it in Select
	updateDependants?: UpdateDependantsFunctionType
}

export const ProjectSelectField = ({
	id,
	isRequired,
	disabled,
	initialProjectId,
	updateDependants,
	...options
 }: Props) => {
	const persistedProject = useSelector((state: RootState) => state.persistedProject);
	const { projects, loadingProjects } = useProjects(options);

	const projectsWithInitial = [...projects];
	// TODO: ovo mozda treba u svim Project selektima primeniti isto i za kategorije ako vise nije aktivna
	if (initialProjectId) {
		const project = persistedProject.itemsMap[initialProjectId];
		if (project) {
			if (!projectsWithInitial.find(item => item.id === project.id)) {
				projectsWithInitial.push(project);
			}
		}
	}

	return (
		<AutoCompleteField
			id={id}
			label='i18n.label.project'
			items={projectsWithInitial}
			getItemId={(item: ProjectResponse) => item.id}
			getItemText={(item: ProjectResponse) => `${getFormatedId(EntityPrefixEnum.PROJECT, item.id)} - ${item.name}`}
			updateDependants={updateDependants}
			loading={loadingProjects}
			isRequired={isRequired}
			disabled={disabled}
		/>
	)
}
