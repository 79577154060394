import { useMemo } from "react";
import { noop } from "utils/commonHelper";
import { filterData, paginateData, sortData } from "./filterSortPageHelpers";
import { FilterSortPageType, FooterCalculatorEnum, GenericColumnModel, InteractionManager } from "../models";
import { CustomTable } from "../CustomTable";
import { calculateSum } from "./footerCalculationHelpers";
import ErrorBoundary from "features/ErrorBoundary";
import { SkeletonTable } from "../SkeletonTable/SkeletonTable";
import { cloneColumnModel } from "features/TableColumns/usePrepareTableColumns";
import { HeaderGroup } from "../Header/Header";

export type LocalCustomTableProps = {
	columns: GenericColumnModel[]
	rowsData: any[]
	filterSortPage: FilterSortPageType
	cellEdited?: (rowData: any, columnId: string, value: any) => Promise<void>
	fetching?: boolean
	hasPagination?: boolean
	compact?: boolean
	interactionManager: InteractionManager
	fill?: boolean
	disabledReorder?: boolean
	additionalHeaderRow?: HeaderGroup[]
	expand?: (rowData: any) => void
	collapse?: (rowData: any) => void
	onGroupClick?: (groupId: string) => void
	isNestedRowsData?: boolean
}

export const LocalCustomTable = ({
	columns,
	rowsData,
	filterSortPage,
	cellEdited,
	fetching,
	hasPagination,
	compact,
	interactionManager,
	fill,
	disabledReorder = false,
	additionalHeaderRow,
	expand,
	collapse,
	onGroupClick,
	isNestedRowsData
}: LocalCustomTableProps) => {
	const rowsDataFiltered = useMemo(
		() => filterData(rowsData, filterSortPage.filters, columns),
		[columns, filterSortPage.filters, rowsData]
	)

	const rowsDataFilterSortPage = useMemo(
		() => {
			const sortedData = sortData(rowsDataFiltered, filterSortPage.sort, columns);
			const paginatedData = hasPagination ? paginateData(sortedData, filterSortPage.offset, (filterSortPage.nestedLimit || filterSortPage.limit)) : sortedData;
			return paginatedData;
		},
		[rowsDataFiltered, filterSortPage, columns, hasPagination]
	)

	const pagination = useMemo(
		() => {
			if (!hasPagination) {
				return undefined;
			}

			const newCount = isNestedRowsData ? rowsDataFiltered.filter(item => item.level === 0).length : rowsDataFiltered.length;
			return {
				offset: filterSortPage.offset,
				limit: filterSortPage.limit,
				count: newCount,
				onChange: interactionManager.changeOffset || noop
			}
		},
		[hasPagination, filterSortPage.offset, filterSortPage.limit, isNestedRowsData, rowsDataFiltered, interactionManager.changeOffset]
	)

	const convertedColumns: GenericColumnModel[] = useMemo(
		() => columns.map((column) => {
			if (column.footerCalculator === FooterCalculatorEnum.SUM && column.footerValue === undefined) {
				// to perserve class type
				const clonedColumn = cloneColumnModel(column);
				clonedColumn.footerValue = calculateSum.bind(null, rowsDataFiltered, column.id)
				return clonedColumn;
			}

			return column;
		}),
		[columns, rowsDataFiltered]
	)

	if (fetching) {
		return <SkeletonTable />
	}

	return (
		<ErrorBoundary location='LocalCustomTable'>
			<CustomTable
				columns={convertedColumns}
				rowsData={rowsDataFilterSortPage}
				filterSortPage={filterSortPage}
				pagination={pagination}
				cellEdited={cellEdited}
				disabledReorder={disabledReorder}
				interactionManager={interactionManager}
				compact={compact}
				fill={fill}
				additionalHeaderRow={additionalHeaderRow}
				expand={expand}
				collapse={collapse}
				onGroupClick={onGroupClick}
			/>
		</ErrorBoundary>
	)
}
