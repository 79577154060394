import { Translation } from 'components/Translations/Translation'
import styles from './tooltip.module.scss'

type Props = {
	content: string
	topPosition: number
	leftPosition: number
	withTranslation?: boolean
}

export const Tooltip = ({ content, topPosition, leftPosition, withTranslation = false }: Props) => {
	return (
		<div className={styles.content} style={{ position: 'fixed', top: `${topPosition}px`, left: `${leftPosition}px` }}>
			{withTranslation ? <Translation i18n={content} /> : content}
		</div>
	)
}
