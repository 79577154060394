import { useEffect, useMemo, useState } from "react";
import { ModuleActivityEnum, ProjectResponse, ProjectStatusEnum, ProjectStatusResponse, TokenTypeEnum } from "services/tenantManagementService";
import { isStatusBySemantic } from "features/StatusResponse/statusResponse";
import { useSelector } from "react-redux";
import { RootState } from "base/reducer/reducer";
import { getIsUserManagerAtAnyOrganizationalUnit, getUserInfo } from "utils/storageUtils";
import { EntityPrefixEnum, emptyArray, getFormatedId } from "utils/commonHelper";
import { PersistItemsReducer } from "features/Persist/reducerTypes";
import { tryCatchJsonByAction } from "utils/fetchUtils";
import { getProjectsForOrgUnitManagerUsersAction } from "./action";
import { isUserPmorSubstitutePm } from "utils/userRoleHelper";
import { findActiveModule } from "./ProjectActiveModules";

export type ProjectOrCategoryType = {
	projectOrCategoryId: number
	isProjectConnected: boolean
}

export type IProjectOrCategory = ProjectOrCategoryType & {
	id: string
	label: string
	isReadOnly: boolean
}

export enum UserRolePermissionEnum {
	PmOrSpm = 'pm_or_spm',
	OrganizationalUnitManager = 'org_unit_manager'
}

// used just to preserve same reference because of React hooks
export const pmOrSpmPermission = [UserRolePermissionEnum.PmOrSpm]
export const pmOrSpmOrOumPermission = [UserRolePermissionEnum.PmOrSpm, UserRolePermissionEnum.OrganizationalUnitManager]
export const OumPermission = [UserRolePermissionEnum.OrganizationalUnitManager]

export type FilterProjectsFunc = (projects: ProjectResponse[]) => ProjectResponse[]

export const isTeamMemberPermittedOnProject = (moduleEnum: ModuleActivityEnum, teamMemberPermission: string, project: ProjectResponse) => {
	switch (moduleEnum) {
		case ModuleActivityEnum.Ticket:
			return project.permissions?.ticketPermission && project.permissions?.ticketPermission[teamMemberPermission];
		case ModuleActivityEnum.Time:
			return project.permissions?.timeTravelPermission && project.permissions?.timeTravelPermission[teamMemberPermission];
		case ModuleActivityEnum.Scope:
			return project.permissions?.scopePermission && project.permissions?.scopePermission[teamMemberPermission];
		case ModuleActivityEnum.Testing:
			return project.permissions?.testingPermission && project.permissions?.testingPermission[teamMemberPermission];
		case ModuleActivityEnum.Training:
			return project.permissions?.trainingPermission && project.permissions?.trainingPermission[teamMemberPermission];
		case ModuleActivityEnum.Schedule:
			return project.permissions?.schedulePermission && project.permissions?.schedulePermission[teamMemberPermission];
		case ModuleActivityEnum.Risk:
			return project.permissions?.riskPermission && project.permissions?.riskPermission[teamMemberPermission];
		default:
			return false;
	}
}

const filterProjectsByStatuses = (projects: ProjectResponse[], statuses: ProjectStatusEnum[], persistedProjectStatus: PersistItemsReducer<ProjectStatusResponse>) => {
	return projects.filter(project => {
		for (let status of statuses) {
			const isStatus = isStatusBySemantic(status, project.statusId, persistedProjectStatus.itemsMap);
			if (isStatus) {
				return true;
			}
		}

		return false;
	})
}

const filterProjectsByUserRolesOrPermissions = (
	projects: ProjectResponse[],
	moduleEnum: ModuleActivityEnum,
	teamMemberPermission: string | undefined,
	userRolePermission: UserRolePermissionEnum[] | undefined
) => {
	if (!teamMemberPermission && !userRolePermission) {
		return projects;
	}

	const filteredProjects = projects.filter((project) => {
		let isTeamMemberPermitted = false;

		if (teamMemberPermission) {
			isTeamMemberPermitted = isTeamMemberPermittedOnProject(moduleEnum, teamMemberPermission, project);
		}

		let isUserRolePermitted = false;
		if (userRolePermission?.includes(UserRolePermissionEnum.PmOrSpm)) {
			isUserRolePermitted = isUserPmorSubstitutePm(project.roleId);
		}

		if (userRolePermission?.includes(UserRolePermissionEnum.OrganizationalUnitManager) && !isUserRolePermitted) {
			isUserRolePermitted = project.isOrganizationalUnitManager;
		}

		return isUserRolePermitted || isTeamMemberPermitted;
	})

	return filteredProjects;
}

const filterProjectsBySelectedOrganizationalUnits = (projects: ProjectResponse[], organizationalUnitIds: number[] | undefined) => {
	if (!organizationalUnitIds?.length){
		return projects;
	}

	return projects.filter((project) => organizationalUnitIds.includes(project.organizationalUnitId!));
}

const fetchProjectsForTimeAndTravelProjectDashboard = async () => {
	const response = await tryCatchJsonByAction(getProjectsForOrgUnitManagerUsersAction);
	if (response.success) {
		return response.items || emptyArray;
	}

	return emptyArray;
}

// organizational unit managers have additional projects because of those users that belong to their organizational unit or bellow
// backend takes care of additional logic, but we need to fetch those projects separately and then merge them with regular ones
const getAdditionalProjects = async (
	persistedOrganizationalUnitManagerProject: PersistItemsReducer<ProjectResponse>,
	isTimeAndTravelProjectDashboard: boolean | undefined,
	isProjectDashboard: boolean | undefined,
	specificProjectsFilter: FilterProjectsFunc | undefined
) => {
	if (!isProjectDashboard) {
		return [];
	}

	const userInfo = getUserInfo();
	if (userInfo.roleId === TokenTypeEnum.SiteAdmin) {
		// SiteAdmin already gets all projects thru persistedProject, so there are no additional ones
		return [];
	}

	const isOrganizationalUnitManager = getIsUserManagerAtAnyOrganizationalUnit();
	if (!isOrganizationalUnitManager) {
		// only organizational unit managers have additional projects
		return [];
	}

	let projects: ProjectResponse[] = [];
	if (isTimeAndTravelProjectDashboard) {
		projects = await fetchProjectsForTimeAndTravelProjectDashboard();
	} else {
		projects = persistedOrganizationalUnitManagerProject.items;
	}

	if (specificProjectsFilter) {
		return specificProjectsFilter(projects);
	}

	return projects;
}

const getFilteredProjects = (
	projects: ProjectResponse[],
	persistedProjectStatus: PersistItemsReducer<ProjectStatusResponse>,
	moduleEnum: ModuleActivityEnum,
	specificProjectsFilter?: FilterProjectsFunc,
	teamMemberPermission?: string,
	userRolePermission?: UserRolePermissionEnum[] | undefined,
	showCompleted?: boolean
) => {
	const projectsFilteredByModule = projects.filter((project) => {
		const module = findActiveModule(moduleEnum, project.activeModules);
		return module?.isActive;
	});

	if (specificProjectsFilter) {
		return specificProjectsFilter(projectsFilteredByModule);
	}

	// filter by statuses
	const statuses = getStatuses(showCompleted);
	const projectsFilteredByModuleAndStatus = filterProjectsByStatuses(
		projectsFilteredByModule,
		statuses,
		persistedProjectStatus
	)

	const userInfo = getUserInfo();
	if (userInfo.roleId === TokenTypeEnum.SiteAdmin) {
		return projectsFilteredByModuleAndStatus;
	}

	const filteredProjects = filterProjectsByUserRolesOrPermissions(
		projectsFilteredByModuleAndStatus,
		moduleEnum,
		teamMemberPermission,
		userRolePermission
	)

	return filteredProjects;
}

const getStatuses = (showCompleted?: boolean) => {
	let statuses: ProjectStatusEnum[] = [ProjectStatusEnum.Released];
	if (showCompleted) {
		statuses.push(ProjectStatusEnum.Completed);
	}
	return statuses;
}

export type UseProjectsAndCategoriesOptions = {
	/** Needs to be true if used inside of Time and Travel Project Dashboard, because it has some specific logic for projects fetching */
	isTimeAndTravelProjectDashboard?: boolean
	/** Needs to be true if used inside of any Project Dashboard, because it has some specific logic for projects fetching and permissions */
	isProjectDashboard?: boolean
	/** Show completed projects (by default, only Released projects are shown). If isProjectDashboard = true, this flag is automatically set to true */
	showCompleted?: boolean
	/** Current module, so projects can be filtered by module */
	moduleEnum: ModuleActivityEnum //
	/** Used to filter projects by project permissions (if user is team member on that project) on that module */
	teamMemberPermission?: string //
	/** Used to filter projects by user roles on project, if undefined all roles are allowed */
	userRolePermission?: UserRolePermissionEnum[] //
	/** If there is some specific logic for filtering, this method is called after projects are filtered by moduleEnum, and all other filters are ignored */
	specificProjectsFilter?: FilterProjectsFunc //
	/** Ids of selected organizational unit with all childrens, used for additional filtering projects whare project is part of organizational unit */
	organizationalUnitIds?: number[]
}

export const useProjects = (options: UseProjectsAndCategoriesOptions) => {
	const {
		isTimeAndTravelProjectDashboard,
		moduleEnum,
		specificProjectsFilter,
		teamMemberPermission,
		userRolePermission,
		organizationalUnitIds
	} = options;
	const isProjectDashboard = options.isProjectDashboard || isTimeAndTravelProjectDashboard;
	const showCompleted = options.showCompleted || isProjectDashboard;

	const persistedProject = useSelector((state: RootState) => state.persistedProject);
	const persistedOrganizationalUnitManagerProject = useSelector((state: RootState) => state.persistedOrganizationalUnitManagerProject);
	const persistedProjectStatus = useSelector((state: RootState) => state.persistedProjectStatus);

	const [additionalProjects, setAdditionalProjects] = useState<ProjectResponse[]>(emptyArray)
	const [loading, setLoading] = useState(true);

	// get projects based on options (isTimeAndTravelProjectDashboard, isDashboard)
	useEffect(
		() => {
			const fetchData = async () => {
				if (persistedOrganizationalUnitManagerProject.isInitialized) {
					setLoading(true);
					const projects = await getAdditionalProjects(
						persistedOrganizationalUnitManagerProject,
						isTimeAndTravelProjectDashboard,
						isProjectDashboard,
						specificProjectsFilter
					);
					setAdditionalProjects(projects);
					setLoading(false);
				}
			}
			fetchData();
		},
		[isTimeAndTravelProjectDashboard, isProjectDashboard, persistedOrganizationalUnitManagerProject, specificProjectsFilter]
	)

	// filter persistedProject
	const projects = useMemo(
		() => {
			if (loading || !persistedProjectStatus.isInitialized) {
				return emptyArray;
			}

			const filteredProjects = getFilteredProjects(
				persistedProject.items,
				persistedProjectStatus,
				moduleEnum,
				specificProjectsFilter,
				teamMemberPermission,
				userRolePermission,
				showCompleted
			)

			let mergedProjects: ProjectResponse[] = [...filteredProjects];

			for (let additionalProject of additionalProjects) {
				const notFound = !mergedProjects.find((item) => item.id === additionalProject.id);
				if (notFound) {
					mergedProjects.push(additionalProject)
				}
			}

			if (organizationalUnitIds?.length !== 0) {
				const filteredProjectsByOrgUnits = filterProjectsBySelectedOrganizationalUnits(
					mergedProjects,
					organizationalUnitIds
				);

				mergedProjects = filteredProjectsByOrgUnits;
			}

			return mergedProjects;
		},
		[loading, persistedProjectStatus, additionalProjects, persistedProject, moduleEnum, specificProjectsFilter, teamMemberPermission, userRolePermission, showCompleted, organizationalUnitIds]
	)

	return {
		projects,
		loadingProjects: loading
	}
}

const useCategories = (options: UseProjectsAndCategoriesOptions) => {
	const { isProjectDashboard } = options;
	const userInfo = getUserInfo();
	const isOrganizationalUnitManager = getIsUserManagerAtAnyOrganizationalUnit();
	const persistedTimeTravelNonProjectCategories = useSelector((state: RootState) => state.persistedTimeTravelNonProjectCategories);

	if (userInfo.isGuest ||
		(isProjectDashboard && !isOrganizationalUnitManager && !(userInfo.roleId === TokenTypeEnum.SiteAdmin))
	) {
		return {
			categories: emptyArray,
			loadingCategories: false
		}
	}

	return {
		categories: persistedTimeTravelNonProjectCategories.activeItems,
		loadingCategories: persistedTimeTravelNonProjectCategories.fetching
	}
}

export const useProjectsAndCategories = (options: UseProjectsAndCategoriesOptions) => {
	const { projects, loadingProjects } = useProjects(options);
	const { categories, loadingCategories } = useCategories(options);

	const projectsOrCategories: IProjectOrCategory[] = useMemo(
		() => {
			if (loadingProjects || loadingCategories) {
				return emptyArray;
			}

			const items: IProjectOrCategory[] = [];

			for (let project of projects) {
				items.push({
					id: `project_${project.id}`,
					label: `${getFormatedId(EntityPrefixEnum.PROJECT, project.id)} - ${project.name}`,
					isProjectConnected: true,
					projectOrCategoryId: project.id,
					isReadOnly: false
				})
			}

			for (let category of categories) {
				items.push({
					id: `category_${category.id}`,
					label: category.name,
					isProjectConnected: false,
					projectOrCategoryId: category.id,
					isReadOnly: category.isReadOnly
				})
			}

			return items;
		},
		[loadingProjects, projects, loadingCategories, categories]
	)

	return {
		projectsOrCategories,
		loadingProjectsAndCategories: loadingProjects || loadingCategories
	}
}

export const useProjectsForTableFilterCallback = (moduleEnum: ModuleActivityEnum) => {
	return useProjects({
		moduleEnum,
		showCompleted: true
	})
}

export const useProjectsAndCategoriesForTableFilterCallback = (moduleEnum: ModuleActivityEnum, specificProjectsFilter?: FilterProjectsFunc) => {
	return useProjectsAndCategories({
		moduleEnum,
		specificProjectsFilter,
		showCompleted: true
	})
}
