import { combineReducers } from 'redux';
import { routesReducer as routes } from 'features/Routes/reducer';
import { usersReducer as persistedUser } from 'containers/Configuration/SiteConfiguration/UserAdministration/Users/reducer';
import { projectTypesReducer as persistedProjectType } from 'containers/Configuration/SiteConfiguration/Projects/ConfigureProjects/Tabs/ProjectType/reducer';
import { projectCategoriesReducer as persistedProjectCategory } from 'containers/Configuration/SiteConfiguration/Projects/ConfigureProjects/Tabs/ProjectCategory/reducer';
import { projectStatusesReducer as persistedProjectStatus } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/Projects/reducer';
import { businessPartnersReducer as persistedBusinessPartner } from 'containers/Configuration/SiteConfiguration/Projects/CreateNewPartner/reducer';
import { countryReducer as persistedCountry } from 'features/Country/reducer';
import { datePatternReducer as persistedDatePattern, timePatternReducer as persistedTimePattern  } from 'features/DateTimePattern/reducer';
import { projectReducer as persistedProject, organizationalUnitManagerProjectReducer as persistedOrganizationalUnitManagerProject } from 'features/Project';
import { currencyReducer as persistedCurrency } from 'features/Currency/reducer';
import { tenantReducer as persistedTenant } from 'features/Tenant/reducer';
import { companyInfoReducer as persistedCompanyInfo } from 'containers/Configuration/SiteConfiguration/CompanyInfoCont/CompanyInfo/reducer';
import { defaultProjectReducer as persistedDefaultProject } from 'containers/Configuration/MyConfiguration/DefaultProject/reducer';
import { teamMembersReducer as persistedTeamMember } from 'containers/Projects/MyProjects/MaintainProject/Tabs/AssignTeamMembers/reducer';
import { projectTeamsReducer as persistedProjectTeam } from 'containers/Projects/MyProjects/MaintainProject/Tabs/Teams/reducer';
import { projectRolesReducer as persistedProjectRole } from 'containers/Projects/MyProjects/MaintainProject/Tabs/Roles/reducer';
import { ticketTypesReducer as persistedTicketType } from 'containers/Configuration/SiteConfiguration/Module/Tickets/Tabs/TicketType/reducer';
import { ticketEnvironmentsReducer as persistedTicketEnvironment } from 'containers/Configuration/SiteConfiguration/Module/Tickets/Tabs/TicketEnvironment/reducer';
import { riskTypesReducer as persistedRiskType } from 'containers/Configuration/SiteConfiguration/Module/Risk/Tabs/RiskType/reducer';
import { ticketAssignedGroupsReducer as persistedTicketAssignedGroup } from 'containers/Configuration/SiteConfiguration/Module/Tickets/Tabs/TicketAssignedGroup/reducer';
import { ticketCategoryReducer as persistedTicketCategory } from 'containers/Configuration/SiteConfiguration/Module/Tickets/Tabs/TicketCategory/reducer';
import { ticketStatusReducer as persistedTicketStatus } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/Tickets/reducer';
import { ticketImpactReducer as persistedTicketImpact } from 'containers/Configuration/SiteConfiguration/Module/Tickets/Tabs/TicketPriority/Impact/reducer';
import { ticketUrgencyReducer as persistedTicketUrgency } from 'containers/Configuration/SiteConfiguration/Module/Tickets/Tabs/TicketPriority/Urgency/reducer';
import { ticketPriorityReducer as persistedTicketPriority } from 'containers/Configuration/SiteConfiguration/Module/Tickets/Tabs/TicketPriority/reducer';
import { reportStatusReducer as persistedReportStatus } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/Reports/reducer';
import { riskStatusReducer as persistedRiskStatus } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/Risks/reducer';
import { invoiceStatusReducer as persistedInvoiceStatus } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/Invoices/reducer';
import { changeRequestStatusReducer as persistedChangeRequestStatus } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/ChangeRequest/reducer';
import { userStatusReducer as persistedUserStatus } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/Users/reducer';
import { ticketClosingIdReducer as persistedTicketClosingId } from 'containers/Configuration/SiteConfiguration/Module/Tickets/Tabs/TicketClosingId/reducer';
import { changeRequestTypeReducer as persistedChangeRequestType } from 'containers/Configuration/SiteConfiguration/Module/Scope/reducer';
import { testCaseStatusReducer as persistedTestCaseStatus } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/TestCases/reducer';
import { trainingStatusReducer as persistedTrainingStatus } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/TrainingStatus/reducer';
import { timeAndTravelStatusReducer as persistedTimeAndTravelStatus } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/TimeAndTravel/reducer';
import { timeTravelNonProjectCategoriesReducer as persistedTimeTravelNonProjectCategories } from 'containers/Configuration/SiteConfiguration/Module/TimeAndTravel/Tabs/NonProjectCategory/reducer';
import { travelCostTypesReducer as persistedTravelCostTypes } from 'containers/Configuration/SiteConfiguration/Module/TimeAndTravel/Tabs/TravelCostTypes/reducer';
import { timeTravelExpenseTypesReducer as persistedTimeTravelExpenseTypes } from 'containers/Configuration/SiteConfiguration/Module/TimeAndTravel/Tabs/ExpenseTypes/reducer';
import { expenseGeneralConfigurationReducer as persistedExpenseGeneralConfiguration } from 'containers/Configuration/SiteConfiguration/Module/TimeAndTravel/Tabs/ExpensesGeneral/reducer';
import { vacationGeneralConfigurationReducer as persistedVacationGeneralConfiguration } from 'containers/Configuration/SiteConfiguration/Module/TimeAndTravel/Tabs/VacationGeneral/reducer';
import { timesheetsGeneralConfigurationReducer as persistedTimesheetsGeneralConfiguration } from 'containers/Configuration/SiteConfiguration/Module/TimeAndTravel/Tabs/TimesheetsGeneral/reducer';
import { travelRequestsReducer as persistedTravelRequests } from 'containers/TimeTravel/MyTimeTravel/Tabs/MyTravelRequests/reducer';
import { themeReducer as theme } from 'features/Theme'
import { organizationalUnitsReducer as persistedOrganizationalUnit } from 'containers/Configuration/SiteConfiguration/General/Company/OrganizationalUnits/reducer';
import { financeCategoryReducer as persistedFinanceCategory } from 'containers/Configuration/SiteConfiguration/Module/Finance/Tabs/Categories/reducer';
import { communicationStatusReportReducer as persistedCommunicationStatusReport } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/Communication/reducer';
import { taskStatusReducer as persistedTaskStatus } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/Tasks/reducer';
import { meetingStatusReducer as persistedMeetingStatus } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/MeetingMinutes/reducer';
import { meetingTypeReducer as persistedMeetingType } from 'containers/Configuration/SiteConfiguration/Module/Communication/Tabs/MeetingType/reducer';
import { scheduleConfigurationReducer as persistedScheduleConfiguration } from 'containers/Schedule/Schedule/Configuration/reducer';
import { departmentsReducer as persistedScopeDepartments } from 'containers/Scope/MaintainScope/Configuration/Department/reducer';
import { stepTypesReducer as persistedScopeStepTypes } from 'containers/Scope/MaintainScope/Configuration/StepType/reducer';
import { userRolesReducer as persistedScopeUserRoles } from 'containers/Scope/MaintainScope/Configuration/UserRole/reducer';
import { timesheetRequiredFieldsReducer as persistedTimesheetRequiredFields } from 'containers/Projects/MyProjects/MaintainProject/Tabs/Timesheets/reducer';
import { tablesConfigReducer as persistedTablesConfig } from 'features/UserTableViewConfig/tableConfig/reducer';

const reducer = combineReducers({
	routes,
	theme,
	persistedTenant,
	persistedCompanyInfo,
	persistedCurrency,
	persistedCountry,
	persistedDatePattern,
	persistedTimePattern,
	persistedBusinessPartner,
	persistedProjectStatus,
	persistedProjectType,
	persistedProjectCategory,
	persistedDefaultProject,

	persistedProject,
	persistedOrganizationalUnitManagerProject,

	// ticket
	persistedTicketType,
	persistedTicketEnvironment,
	persistedTicketAssignedGroup,
	persistedTicketCategory,
	persistedTicketStatus,
	persistedTicketImpact,
	persistedTicketUrgency,
	persistedTicketPriority,
	persistedTicketClosingId,

	// report
	persistedReportStatus,
	persistedRiskStatus,
	persistedInvoiceStatus,
	persistedChangeRequestStatus,

	// scope
	persistedChangeRequestType,
	persistedScopeDepartments,
	persistedScopeStepTypes,
	persistedScopeUserRoles,

	// testing
	persistedTestCaseStatus,

	// training
	persistedTrainingStatus,

	// time and travel
	persistedTimeAndTravelStatus,
	persistedTimeTravelNonProjectCategories,
	persistedTravelCostTypes,
	persistedTimeTravelExpenseTypes,
	persistedExpenseGeneralConfiguration,
	persistedVacationGeneralConfiguration,
	persistedTimesheetsGeneralConfiguration,
	persistedTravelRequests,
	persistedTimesheetRequiredFields,

	// finance
	persistedFinanceCategory,

	persistedUserStatus,
	persistedUser,
	persistedTeamMember,
	persistedProjectTeam,
	persistedProjectRole,
	persistedOrganizationalUnit,

	// communication
	persistedCommunicationStatusReport,
	persistedTaskStatus,
	persistedMeetingStatus,
	persistedMeetingType,

	// schedule
	persistedScheduleConfiguration,

	// risk
	persistedRiskType,

	// table config
	persistedTablesConfig
});

export type RootState = ReturnType<typeof reducer>;

export default reducer;
