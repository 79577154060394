import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { ModuleActivityEnum, ProjectStatusEnum } from 'services/tenantManagementService';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { RootState } from 'base/reducer/reducer';
import { ColumnContainer } from 'components/Layout';
import { Subtract } from 'utility-types';
import { ProjectOrCategorySelect } from '../controls/ProjectOrCategorySelect';
import { isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import { ProjectOrCategoryType } from '../projectHooks';

export type ProjectOrCategoryComponentProps = {
	projectOrCategory: ProjectOrCategoryType
	disabledEdit?: boolean
}

export const ProjectOrCategoryParamName = 'projectOrCategoryId';
export const IsProjectConnectedParamName = 'isProjectConnected';

export type ProjectOrCategoryPickerParams = {
	[ProjectOrCategoryParamName]?: string
	[IsProjectConnectedParamName]?: string
}

export function WithProjectOrCategoryPicker<P extends ProjectOrCategoryComponentProps>(
	Component: React.ComponentType<P>,
	moduleEnum: ModuleActivityEnum,
	useMargin: boolean = false
) {

	const HighOrderComponent = (props: Subtract<P, ProjectOrCategoryComponentProps>) => {
		const history = useHistory();
		const routematch = useRouteMatch();
		const { projectOrCategoryId: urlIdString, isProjectConnected: urlIsProjectConnected }: ProjectOrCategoryPickerParams = useParams();
		const urlId = urlIdString ? parseInt(urlIdString) : undefined;
		const persistedDefaultProject = useSelector((state: RootState) => state.persistedDefaultProject);
		const persistedProjectStatus = useSelector((state: RootState) => state.persistedProjectStatus);
		const persistedProject = useSelector((state: RootState) => state.persistedProject);

		// initial state should be the one from URL, and later on, state from URL should be ignored
		let initialProjectOrCategory: ProjectOrCategoryType | undefined;
		if (urlId && urlIsProjectConnected) {
			initialProjectOrCategory = {
				projectOrCategoryId: urlId,
				isProjectConnected: urlIsProjectConnected === 'true'
			}
		}
		const [selectedProjectOrCategory, setSelectedProjectOrCategory] = useState<ProjectOrCategoryType | undefined>(initialProjectOrCategory);

		useEffect(
			() => {
				// if there is no initial state from URL, nor selected projectOrCategory, set default project as initial state (if it exists)
				if (!selectedProjectOrCategory && persistedDefaultProject.value.projectId) {
					setSelectedProjectOrCategory({
						projectOrCategoryId: persistedDefaultProject.value.projectId,
						isProjectConnected: true
					})
				}
			},
			[selectedProjectOrCategory, persistedDefaultProject]
		)

		const url = useMemo(
			() => {
				if (selectedProjectOrCategory && urlId !== selectedProjectOrCategory.projectOrCategoryId) {
					return routematch.path.split(`/:${ProjectOrCategoryParamName}`)[0] + '/' + selectedProjectOrCategory.projectOrCategoryId + '/' + selectedProjectOrCategory.isProjectConnected;
				}
			},
			[routematch.path, selectedProjectOrCategory, urlId]
		)

		useEffect(
			() => {
				if (url) {
					history.replace(url);
				}
			},
			[history, url]
		)

		const WrapperComponent = useMemo(
			() => useMargin ? ColumnContainer : React.Fragment,
			[]
		)

		const selectedProjectStatusId = useMemo(
			() => {
				if (selectedProjectOrCategory?.isProjectConnected === true) {
					return persistedProject.itemsMap[selectedProjectOrCategory.projectOrCategoryId]?.statusId;
				}
			},
			[selectedProjectOrCategory, persistedProject.itemsMap]
		)

		const componentPropsMemo = useMemo(
			() => {
				return {
					...props,
					projectOrCategory: selectedProjectOrCategory,
					disabledEdit: !isStatusBySemantic(ProjectStatusEnum.Released, selectedProjectStatusId, persistedProjectStatus.itemsMap) && !(selectedProjectOrCategory?.projectOrCategoryId && selectedProjectOrCategory.isProjectConnected === false)
				} as P
			},
			[selectedProjectOrCategory, selectedProjectStatusId, persistedProjectStatus, props]
		)

		return (
			<WrapperComponent>
				<SmartContainer>
					<SmartItem>
						<ProjectOrCategorySelect
							value={selectedProjectOrCategory}
							onChange={setSelectedProjectOrCategory}
							loading={persistedDefaultProject.fetching}
							moduleEnum={moduleEnum}
							showCompleted
						/>
					</SmartItem>
				</SmartContainer>
				{selectedProjectOrCategory && <Component {...componentPropsMemo} />}
			</WrapperComponent>
		)
	}

	return HighOrderComponent;
}
