export const calculateSum = (filteredData: any[], columnId: string) => {
	let calculated = 0;

	for (let rowData of filteredData || []) {
		const value = rowData[columnId];
		calculated += value || 0;
	}

	return calculated;
}
