import appSettings from "settings/appSettings";
import styles from './translation.module.scss';
import { useGetTranslation } from './translationUtils';

type Props = {
	// sometimes i18n is undefined somehow, so added "| undefined" just in case
	i18n: string | undefined
	args?: string[]
}

export const Translation = ({ i18n, args = [] }: Props) => {
	const markTranslation = appSettings.getMarkTranslations();
	const getTranslation = useGetTranslation();

	if (i18n === undefined) {
		return <></>
	}

	const translation = getTranslation(i18n, args)

	// if marTranslation flag is set, and tthere is no translation, use mark
	if (markTranslation && translation === i18n) {
		return <span className={translation?.startsWith('i18n') ? styles.missing_key : styles.not_translated}>{translation}</span>
	}

	return <span>{translation}</span>
}
