import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';
import { PersistItemsReducer } from 'features/Persist/reducerTypes';
import { DateTimePatternResponse } from 'services/tenantManagementService';
import { DATE_PATTERN_FETCHING, DATE_PATTERN_SET, TIME_PATTERN_FETCHING, TIME_PATTERN_SET } from './action';

export const datePatternReducer = (state = new PersistItemsReducer<DateTimePatternResponse>(), action: PersistItemsActionType<DateTimePatternResponse> | FetchingActionType): PersistItemsReducer<DateTimePatternResponse> => {
	switch (action.type) {
		case DATE_PATTERN_FETCHING:
			const { fetching } = action as FetchingActionType;

			return {
				...state,
				fetching
			}
		case DATE_PATTERN_SET:
			const { items } = action as PersistItemsActionType<DateTimePatternResponse>;

			const itemsMap: { [id: number]: DateTimePatternResponse } = items.reduce(
				(map: { [id: number]: DateTimePatternResponse }, item) => {
					map[item.id] = item;
					return map;
				},
				{}
			);

			return {
				items,
				itemsMap,
				fetching: false,
				isInitialized: true
			}
		default:
			return state;
	}
};

export const timePatternReducer = (state = new PersistItemsReducer<DateTimePatternResponse>(), action: PersistItemsActionType<DateTimePatternResponse> | FetchingActionType): PersistItemsReducer<DateTimePatternResponse> => {
	switch (action.type) {
		case TIME_PATTERN_FETCHING:
			const { fetching } = action as FetchingActionType;

			return {
				...state,
				fetching
			}
		case TIME_PATTERN_SET:
			const { items } = action as PersistItemsActionType<DateTimePatternResponse>;

			const itemsMap: { [id: number]: DateTimePatternResponse } = items.reduce(
				(map: { [id: number]: DateTimePatternResponse }, item) => {
					map[item.id] = item;
					return map;
				},
				{}
			);

			return {
				items,
				itemsMap,
				fetching: false,
				isInitialized: true
			}
		default:
			return state;
	}
};
