import React, { useCallback, useMemo, useState } from 'react';
import Button from 'components/Button';
import { ColumnContainer, RowContainer } from 'components/Layout';
import { FieldMessage } from './addons';
import { ADD_VALIDATOR_TYPE, useRegisteredValidatorsReducer } from './useRegisteredValidatorsReducer';
import { UnsavedChangesGuard } from './UnsavedChangesGuard/UnsavedChangesGuard';
import ErrorBoundary from 'features/ErrorBoundary';
import { isAutoCompleteHit, isAutoCompleteRemoteHit, isMultiSelectHit, isTextEditorHit } from './controls';

// when there is some validation problem on form, that is not strictly on one field, backend is sending it thru property with id ''
export const globalErrorKey = '';

export type ValidatorFunctionType = (value: any, values: any, id: string) => any;

export interface IFormContext {
	// try to set type to those two any's, also on errors in Form -> useState
	values?: any
	errors?: any
	onFieldChange?: (id: string, value: any, dependantValues: KeyValue) => void
	validateField?: (id: string, value?: any) => void
	disabled?: boolean
	registerValidators?(id: string, validatorFunctions: Array<ValidatorFunctionType>): void
	setFieldValue?(id: string, fieldValue: any): void
	setFieldError?(id: string, fieldError: any): void
}

export const FormContext = React.createContext<IFormContext>({});

type KeyValue = {
	[key: string]: any
}

type Props = {
	values: KeyValue
	initialValues?: KeyValue
	onChange(values: KeyValue): void
	render(): any
	disabled?: boolean
	disableUnsavedChangesGuard?: boolean

	// onSubmit can have better return type, more strict type
	onSubmit?(): KeyValue
	submitButtonText?: string
	hideSubmitButton?: boolean
	onCancel?(): void
	cancelButtonText?: string
	hideCancelButton?: boolean
	renderAdditionalButtons?(disabled?: boolean, handleSubmitCallback?: () => void, isSubmitting?: boolean): any
	hideButtons?: boolean
}

export const Form = ({
	values,
	initialValues,
	onChange: onChangeProp,
	render,
	disabled,
	disableUnsavedChangesGuard,
	onSubmit,
	submitButtonText = 'i18n.button.save',
	hideSubmitButton,
	onCancel,
	cancelButtonText = 'i18n.button.cancel',
	hideCancelButton,
	renderAdditionalButtons,
	hideButtons
}: Props) => {
	// errors.someField can be both undefined
	const [errors, setErrors] = useState<any>({});
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [registeredValidators, dispatchRegisteredValidators] = useRegisteredValidatorsReducer();

	// try to improve it, so there is undefined (or no property at all) instead of ''
	const haveErrorsCallback = useCallback(
		() => {
			let haveError: boolean = false;
			Object.keys(errors).map((key: string) => {
				// TableField - object[]
				if (errors[key] instanceof Array) {
					for (const rowErrors of errors[key]) {
						if (!rowErrors) {
							continue;
						}
						const keys = Object.keys(rowErrors);
						const firstKey = keys[0];

						if (keys.length !== 1 || firstKey !== globalErrorKey) {
							haveError = true;
							break;
						}
					}
				// other fields - string
				} else if (errors[key].length > 0 && key !== globalErrorKey) {
					haveError = true;
				}
				return null; // just for eslint
			});
			return haveError;
		},
		[errors]
	)

	const validateFieldCallback = useCallback(
		(id: string, value?: any) => {
			let newError: string = '';
			const currentValue = value || values[id];

			for (const validator of registeredValidators[id] || []) {
				newError = validator(currentValue, { ...values, [id]: value}, id)
				if (newError) {
					break;
				}
			}

			setErrors((state: any) => {
				return {
					...state,
					[id]: newError
				}
			});

			return newError === '';
		},
		[values, registeredValidators]
	)

	const validateFormCallback = useCallback(
		() => {
			let valid = true;

			for (const fieldName of Object.keys(registeredValidators)) {
				valid = validateFieldCallback(fieldName) && valid;
			}

			return valid;
		},
		[registeredValidators, validateFieldCallback]
	)

	const handleSubmitCallback = useCallback(
		async (): Promise<void> => {
			setIsSubmitting(true);

			if (validateFormCallback() && onSubmit) {
				let serverErrors = await onSubmit();

				setErrors(serverErrors || {});
			}

			setIsSubmitting(false);
		},
		[validateFormCallback, onSubmit]
	)

	const handleCancelCallback = useCallback(
		() => onCancel && onCancel(),
		[onCancel]
	)

	const onFieldChangeCallback = useCallback(
		(id: string, value: any, dependantValues?: KeyValue) => {
			onChangeProp({
				...values,
				[id]: value !== "" ? value : undefined,
				...dependantValues
			})

			// if changed field has error, validate it to check if that error is solved
			if (errors[id]) {
				validateFieldCallback(id, value);
			}
		},
		[validateFieldCallback, errors, values, onChangeProp]
	)

	const registerValidatorsCallback = useCallback(
		(id: string, validatorFunctions: Array<ValidatorFunctionType>) => {
			dispatchRegisteredValidators({
				type: ADD_VALIDATOR_TYPE,
				id,
				validatorFunctions
			})
		},
		[dispatchRegisteredValidators]
	);

	const setFieldError = useCallback(
		(id: string, fieldError: any) => {
			setErrors((state: any) => {
				return {
					...state,
					[id]: fieldError
				}
			})
		},
		[]
	)

	const setFieldValue = useCallback(
		(id: string, fieldValue: any) => {
			onChangeProp({
				...values,
				[id]: fieldValue
			})
		},
		[onChangeProp, values]
	)

	const context: IFormContext = useMemo(
		() => {
			return {
				values,
				errors,
				onFieldChange: onFieldChangeCallback,
				validateField: validateFieldCallback,
				disabled,
				registerValidators: registerValidatorsCallback,
				setFieldValue: setFieldValue, // added because of TableField
				setFieldError: setFieldError // added because of TableField
			}
		},
		[values, errors, validateFieldCallback, onFieldChangeCallback, disabled, registerValidatorsCallback, setFieldError, setFieldValue]
	)

	const hasUnsavedChanges = useCallback(
		() => {
			// if form is submitting and this method is called, it means that onSubmit handler called route switching because success = true
			if (disabled || isSubmitting) {
				return false;
			}

			// implement some better check, deep equal
			// Date - when changed to same, it is different object
			for (const key of Object.keys(values)) {
				const value = values[key] !== "" ? values[key] : undefined; // UserForm initials were "" and initialValue.initials is undefined
				const initialValue = initialValues && (initialValues[key] !== "" ? initialValues[key] : undefined);

				// eslint-disable-next-line
				if (value != initialValue) {
					return true;
				}
			}

			return false;
		},
		[disabled, values, initialValues, isSubmitting]
	)

	const handleKeyUp = useCallback(
		(e: React.KeyboardEvent) => {
			if (e.key === 'Enter') {
				if (e.target && (e.target as any).type === 'textarea') {
					return;
				}

				if (isTextEditorHit(e.target as HTMLElement)) {
					return;
				}

				if (isAutoCompleteHit(e.target as HTMLElement)) {
					return;
				}

				if (isAutoCompleteRemoteHit(e.target as HTMLElement)) {
					return;
				}

				if (isMultiSelectHit(e.target as HTMLElement)) {
					return;
				}

				e.stopPropagation();

				if (disabled || isSubmitting || haveErrorsCallback()) {
					return;
				}

				handleSubmitCallback();
			}
		},
		[disabled, handleSubmitCallback, haveErrorsCallback, isSubmitting]
	)

	return (
		<ErrorBoundary location='Form'>
			<FormContext.Provider value={context}>
				<ColumnContainer>
					<div onKeyUp={handleKeyUp}>
						{render()}
					</div>

					{context.errors[globalErrorKey] &&
						<RowContainer justifyContent='flex-end'>
							<FieldMessage message={context.errors[globalErrorKey]} />
						</RowContainer>
					}

					{!hideButtons &&
						<RowContainer justifyContent='flex-end' flex={hideSubmitButton && hideCancelButton && renderAdditionalButtons ? 1 : undefined}>
							{!hideSubmitButton &&
								<Button
									text={submitButtonText}
									onClick={handleSubmitCallback}
									disabled={disabled || haveErrorsCallback()}
									isLoading={isSubmitting}
								/>
							}
							{renderAdditionalButtons && renderAdditionalButtons(isSubmitting || disabled, handleSubmitCallback, isSubmitting)}
							{!hideCancelButton &&
								<Button
									text={cancelButtonText}
									color='neutral'
									disabled={disabled}
									onClick={handleCancelCallback}
								/>
							}
						</RowContainer>
					}
				</ColumnContainer>

				{/* unsaved changes */}
				{!disableUnsavedChangesGuard &&
					<UnsavedChangesGuard shouldBlockNavigation={hasUnsavedChanges} />
				}
			</FormContext.Provider>
		</ErrorBoundary>
	)
}
